import { headerHeight } from "../../config/constants";
const styles = {
  container: {
    paddingHorizontal: 48,
    paddingTop: 14,
    flex: 1,
    height: "100vh",
  },
  myOrdersTitle: {
    fontSize: 24,
    fontWeight: "700",
    lineHeight: 30,
  },
  truckImage: {
    marginHorizontal: 10,
    marginTop: 10,
  },
  orderContainer: {
    borderWidth: 1,
    borderColor: "#E5E5E5",
    borderRadius: 12,
    paddingHorizontal: 10,
    paddingVertical: 12,
    marginTop: 6,
    marginBottom: 6,
    flexDirection: "row",
    width: 434,
    height: 210,
  },
  orderInfo: {
    flex:1,
    justifyContent: "space-around",
    marginLeft: 10,
    minHeight: 135,
    paddingBottom: 8,
  },
  orderTextContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 8,
    flexWrap: "wrap",
    // maxWidth: 150,
  },
  orderName: {
    fontSize: 16,
    fontWeight: "600",
  },
  orderText: {
    fontSize: 14,
    fontWeight: "400",
    wordBreak: "break-word",
    maxWidth: 150,
    overflow: "hidden", // Hides overflow content
    whiteSpace: "nowrap", // Keeps text on one line
    textOverflow: "ellipsis", // Adds ellipsis to truncated text
  },
  icon: {
    marginRight: 4,
  },
  line: {
    marginHorizontal: 10,
  },
  createNewOrder: {
    width: 129,
  },
  footerBlock: {
    height: 100,
  },
  subHeaderWrapper: {
    flexDirection: "row",
    marginBottom: 20,
    // marginTop: 20,
    justifyContent: "space-between",
  },
  bidText: {},
  status: {
    width: 8,
    height: 8,
    borderRadius: 4,
    marginRight: 4,
  },
  statusRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  button: {
    backgroundColor: "#1DCE80",
    flex: 1,
    width: 85,
    height: 34,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 12,
    marginTop: 8,
  },
  completeButton: {
    width: 127,
    height: 34,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 12,
    marginTop: 8,
  },
  buttonRow: {
    marginHorizontal: 10,
  },
  disputeButton: {
    width: 107,
    height: 34,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 12,
    marginTop: 8,
  },
  buttonText: {
    color: "#fff",
    fontSize: 14,
    fontWeight: "600",
  },
  buttonsContainer: {
    flexDirection: "row",
    gap: 60
  },
  buttonsRow: {
    flexDirection: "row",
  },
  cancel: {
    borderWidth: 1,
    borderColor: "#000",
    marginRight: 3,
    width: 96,
  },
  acButton: {
    marginLeft: 3,
    width: 96,
  },
  addPhotoContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 12,
  },
  addPhotoText: {
    fontSize: 12,
    color: "#171717",
    marginLeft: 12,
  },
  image: {
    borderRadius: 8,
  },
  emptyText: {
    fontSize: 16,
    textAlign: "center",
    margin: 32,
  },
  emptyContainer: {
    alignItems: "center",
    paddingTop: 40,
  },
  wrapper: {
    flex: 1,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    // marginTop: (window.innerHeight - 600) / 2 - headerHeight,
  },
  auctionStatus: {
    marginBottom: 8,
  },
  inactiveContainer: {
    opacity: 0.5, // Semi-transparent to show it's inactive
    backgroundColor: "#F3F4F6", // Muted background color
  },
  sendingContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 8,
  },
  sendingText: {
    fontSize: 14,
    marginLeft: 8,
    color: "#4A5568",
  },
  sendOrderButton: {
    backgroundColor: "#FFA500",
  },
  draftText: {
    fontSize: 12,
    color: "gray",
    marginTop: 5,
  },
};

export default styles;
