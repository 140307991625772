import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 48,
  //  paddingTop: 20,
    paddingBottom: 40,
  },
  stepper: {
    alignItems: 'center',
  },
  divider: {
    height: 16,
  },
  emptySpace: {
    height: 50,
  },
  leftIcon: {
    marginLeft: 12,
  },
  row: {
    flexDirection: 'row',
    marginTop: 16,
    marginBottom: 16,
  },
  textContainer: {
    flexShrink: 1,
    marginLeft: 8,
    color: '#525252',
    fontSize: 14,
  },
  h1: {
    fontSize: 24,
    fontWeight: "700",
    lineHeight: 30,
    marginBottom: 20,
    marginTop: 20,
  },
});

export default styles;
