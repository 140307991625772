import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
    // paddingTop: 20,
    paddingBottom: 20,
  },
  imagesRow: {
    marginTop: 10,
    flexDirection: "row",
    gap: 8,
  },
  image: {
    borderRadius: 12,
    height: 250,
    width: "100%",
    marginBottom: 10,
  },
  imageSmall: {
    marginRight: 8,
    borderWidth: 2,
    borderColor: "#ddd",
    width: "100px",
    height: "100px",
    objectFit: "cover",
    borderRadius: 12,
    filter: "blur(0.5px)", // Helps with display on smaller resolutions
  },
  fullImage: {
    width: "100%",
    height: "100%",
    borderRadius: 12,
  },
  header: {
    color: "#171717",
    fontSize: 20,
    fontWeight: "700",
    marginVertical: 20,
  },
  badges: {
    flexDirection: "row",
    marginTop: 10,
  },
  badge: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: "#DAF8EA",
    alignItems: "center",
    justifyContent: "center",
    height: 32,
    borderRadius: 8,
  },
  badgeText: {
    color: "#1DCE80",
    fontSize: 14,
    marginLeft: 8,
  },
  vinBadge: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    flexDirection: "row",
    borderRadius: 10,
    backgroundColor: "#fff",
    marginTop: 10,
  },
  headerBlock: {
    color: "#171717",
    fontSize: 16,
    fontWeight: "700",
    marginVertical: 10,
  },
  recipientRow: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 7,
  },
  vinText: {
    marginLeft: 12,
  },
  greyText: {
    fontWeight: "500",
    fontSize: 14,
    color: "#737373",
  },
  locationWrapper: {
    flexDirection: "row",
    marginTop: 12,
  },
  locationTextWrapper: {
    marginLeft: 12,
  },
  locationText: {
    color: "#737373",
    fontSize: 14,
    fontWeight: "500",
  },
  calendarIcon: {
    marginRight: 8,
  },
  addressIcon: {
    marginRight: 6,
    marginLeft: 2,
  },
  button: {
    marginTop: 16,
  },
  blockWrapper: {
    borderTopWidth: 1,
    paddingBottom: 8,
    marginTop: 24,
    borderColor: "#D4D4D4",
  },
  divider: {
    height: 100,
  },
  buttonsRow: {
    flexDirection: "row",
    flex: 2,
  },
  cancel: {
    flex: 1,
    marginRight: 3,
  },
  acButton: {
    flex: 1,
    marginLeft: 3,
  },
  dcButton: {
    flex: 1,
    marginLeft: 3,
  },
  status: {
    flexDirection: "row",
    marginBottom: 15,
    gap: 6,
  },
  row: {
    flexDirection: "row",
    gap: 5,
  },
  sendOrderButton: {
    backgroundColor: "#FFA500",
    marginTop: 16,
  },
  inactiveContainer: {
    opacity: 0.5, // Semi-transparent to show it's inactive
    backgroundColor: "#F3F4F6", // Muted background color
  },
  sendingOrderContainer: {
    marginTop: 24,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  editOrderButton: {
    marginTop: 16,
    backgroundColor: "#00C879",
  },
  activeImage: {
    borderColor: "#1DCE80", // Highlight the selected image with green border
  },
  thumbnailsRow: {
    flexDirection: "row",
    marginVertical: 10,
  },
  thumbnail: {
    width: 90,
    height: 60,
    borderRadius: 8,
    marginHorizontal: 5,
    borderWidth: 2,
  },
  buttonText: {
    color: "#fff",
    fontWeight: "600",
  },
  locationPinWrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginVertical: 10,
  },
  locationDetailsWrapper: {
    flexDirection: "row",
    alignItems: "flex-start",
    flex: 1,
  },
  dateRow: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 4,
  },
  pinContainer: {
    alignItems: "flex-end",
    marginLeft: 20, // Adds slight separation from the location
  },
  pinText: {
    fontSize: 18,
    letterSpacing: 4,
  },
  iconWrapper: {
    marginLeft: 8,
  },
  inactiveContainer: {
    opacity: 0.5, // Makes button semi-transparent to indicate it's inactive
    backgroundColor: "#F3F4F6", // Muted background color for disabled state
  },
  sendButton: {
    borderRadius: 6,
    marginRight: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  sendButtonContainer: {
    flexDirection: "column",
  },
});

export default styles;
