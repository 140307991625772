import * as React from "react";
import {
  Center,
  Button,
  Checkbox,
  FormControl,
  Input,
  Text,
  View,
  WarningOutlineIcon,
  Box,
  useToast
} from "native-base";
import styles from "./styles";
import { Email, Phone } from "../../assets/images/svg";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setUser } from "../../networking/auth/authSlice";
import axios from "axios";
import { API_URL } from "../../config";
import { useLocation, useNavigate } from "react-router-dom";
import { isMobile } from "../../config/constants";
import PhoneInput from "react-phone-number-input/input";
import { toastErrorConfig } from "../../config/constants";

export default function SignUpScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const type = location?.pathname;
  const toast = useToast()
  const dispatch = useDispatch();
  const [hidden, setHidden] = useState(true);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [checked, setChecked] = React.useState(false);
  const [isPhoneFocused, setIsPhoneFocused] = useState(false);

  // navigation.setOptions({
  //   name: "Sign Up",
  //   subheader: "Create account and enjoy all services",
  // });

  const showPassword = () => {
    setHidden(!hidden);
  };

  const goToSignIn = () => {
    if (type === "/signup/email") {
      navigate("/signin/email");
    } else {
      navigate("/signin/phone");
    }
  };

  const confirmCode = () => {
    dispatch(
      setUser({
        email,
        phone,
      })
    );
    if ((email || phone) && checked) {
      const verificationData = {};
      let sendType = "";
      if (type === "/signup/email") {
        if (!email.includes("@") || !email.includes(".")) {
          toast.show({
            description: "Email is invalid",
            ...toastErrorConfig
          })
          return;
        }
        verificationData.email = email;
        sendType = "email";
      }
      if (type === "/signup/phone") {
        const isValidPhone = phone && phone.replace(/\D/g, "").length === 11;

        if (!isValidPhone) {
          toast.show({
            description: "Please enter a valid 10-digit phone number.",
            ...toastErrorConfig
          })
          return;
        }
        verificationData.phone = phone;
        sendType = "phone";
      }
      axios.post(`${API_URL}/verification/${sendType}`, verificationData);
      if (type === "/signup/email" && email && checked) {
        navigate(`/confirm/signup/email/${email}`);
      }
      if (type === "/signup/phone" && phone && checked) {
        navigate(`/confirm/signup/phone/${phone}`);
      }
    }
  };

  return (
    <Box style={styles.wrapper}>
      <View style={isMobile() ? styles.containerMobile : styles.container}>
        <Center width={"282"}>
          <Text fontSize="2xl" bold>
            Sign Up
          </Text>
          <Text mt="5px" mb="10px" fontSize="md">
            Create account and enjoy all services
          </Text>
        </Center>
        <FormControl>
          {type === "/signup/email" && (
            <>
              <FormControl.Label>Email</FormControl.Label>
              <Input
                textContentType="emailAddress"
                keyboardType="email-address"
                autoCapitalize="none"
                type="email"
                autoCorrect={false}
                autoCompleteType="email"
                value={email}
                onChangeText={setEmail}
                InputLeftElement={
                  <View style={styles.leftIcon}>
                    <Email />
                  </View>
                }
                placeholder="Email Address"
              />
              <FormControl.ErrorMessage
                leftIcon={<WarningOutlineIcon size="xs" />}
              >
                Try different from previous passwords.
              </FormControl.ErrorMessage>
            </>
          )}
          {type === "/signup/phone" && (
            <>
              <FormControl.Label>Phone Number</FormControl.Label>
              <View
                style={[
                  {
                    flexDirection: "row",
                    alignItems: "center",
                    borderRadius: 12,
                    borderColor: isPhoneFocused ? "#1DCE80" : "#D9D9D9",
                    backgroundColor: isPhoneFocused ? "#E9F8EF" : "FAFAFA",
                    borderWidth: 1,
                    height: 46,
                    width: "100%",
                    paddingHorizontal: 12,
                    outline: isPhoneFocused ? "1px solid #1DCE80" : "none",
                  },
                ]}
              >
                <View style={{ marginRight: 10 }}>
                  <Phone />
                </View>
                <PhoneInput
                  value={phone}
                  onChange={setPhone}
                  type="phone"
                  maxLength="14"
                  placeholder="(222) 333-4455"
                  defaultCountry="US"
                  style={{
                    paddingLeft: 0,
                    outline: "none",
                    flex: 1,
                    borderWidth: 0,
                    backgroundColor: isPhoneFocused ? "#E9F8EF" : "#FAFAFA",
                  }}
                  onFocus={() => setIsPhoneFocused(true)}
                  onBlur={() => setIsPhoneFocused(false)}
                />
              </View>
            </>
          )}
          {/* <View style={styles.divider} />
          <FormControl.Label>Password</FormControl.Label>
          <Input
            type={hidden ? "password" : "text"}
            value={password}
            onChangeText={setPassword}
            InputLeftElement={
              <View style={styles.leftIcon}>
                <Lock />
              </View>
            }
            InputRightElement={
              <TouchableOpacity onPress={showPassword} style={styles.rightIcon}>
                {hidden ? <EyeSlash /> : <Eye />}
              </TouchableOpacity>
            }
            placeholder="Password"
          /> */}
          <View style={styles.divider} />
          <View style={styles.row}>
            <Center>
              <Checkbox
                value="checkbox"
                onChange={(state) => {
                  setChecked(state);
                }}
                accessibilityLabel="checkbox"
              />
            </Center>

            <Text style={styles.textContainer} fontSize="sm">
              I agree to the company{" "}
              <Text style={styles.actionText}>Terms of Service</Text> and{" "}
              <Text style={styles.actionText}>Privacy Policy</Text>
            </Text>
          </View>
        </FormControl>
        <Button style={styles.button} onPress={confirmCode}>
          Continue
        </Button>
        <View style={styles.divider} />
        <View>
          <Text fontSize="sm">
            Already have an account?{" "}
            <Text style={styles.actionText} onPress={goToSignIn}>
              Sign In
            </Text>
          </Text>
        </View>
        <View style={styles.divider} />
      </View>
    </Box>
  );
}
