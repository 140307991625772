const styles = {
  wrapper: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 20,
    minHeight: "90vh",
  },
  containerMobile: {
    maxWidth: 380,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    backgroundColor: "#FAFAFA",
    borderRadius: 16,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.25,
    shadowRadius: 2.22,
    elevation: 5,
  },
  container: {
    alignItems: "center",
    justifyContent: "center",
    padding: 40,
    width: 415,
    backgroundColor: "#FAFAFA",
    borderRadius: 16,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.25,
    shadowRadius: 2.22,
    elevation: 5,
  },
  forgotPassword: {
    marginLeft: "auto",
    marginTop: 4,
  },
  changePage: {
    paddingBottom: 30,
    marginTop: 10,
  },
  actionText: {
    fontWeight: "600",
    color: "#1DCE80",
  },
  leftIcon: {
    marginLeft: 12,
  },
  rightIcon: {
    marginRight: 12,
  },
  button: {
    marginTop: "auto",
  },
  divider: {
    height: 16,
  },
  row: {
    flexDirection: "row",
  },
  textContainer: {
    flexShrink: 1,
    marginLeft: 8,
  },
};

export default styles;
