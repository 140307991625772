import * as React from "react";
import {
  Button,
  Image,
  ScrollView,
  Text,
  useToast,
  View,
  Pressable,
  AlertDialog,
  Modal,
  HStack,
  Spinner,
  IconButton,
  ArrowBackIcon,
  ArrowForwardIcon,
} from "native-base";
import styles from "./styles";
import { useEffect, useState } from "react";
import {
  CalendarGrey,
  GeoGreen,
  GreenEmail,
  GreenPhone,
  GreenUser,
  Height,
  Length,
  LocationGreen,
  TrailerClosed,
  TrailerOpen,
  VinGreen,
  Weight,
  Width,
} from "../../../assets/images/svg";
import AddressGrey from "../../../assets/images/svg/address-grey";
import { useDispatch, useSelector } from "react-redux";
import { createOrder, editOrder } from "../../../networking/orders/ordersApi";
import { formatStringToDate } from "../../../utils";
import { GOOGLE_API_KEY, imageHost } from "../../../config";
import { selectInputValues } from "../../../networking/orders/ordersSlice";
import BottomNavigator from "../../../components/BottomNavigator";
import { useNavigate, useParams } from "react-router-dom";
import {
  APIProvider,
  Map,
  useMap,
  useMapsLibrary,
} from "@vis.gl/react-google-maps";
import {
  toastErrorConfig,
  toastSuccessConfig,
} from "../../../config/constants";
import { removeOrderById } from "../../../networking/profile/profileSlice";

export default function PreviewScreen() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const [shownDisclaimerModal, setShownDisclaimerModal] = useState(false);
  const { active } = useSelector((state) => state.profile);
  const order = useSelector(selectInputValues);
  const position = { lat: 40.6973709, lng: -74.1444872 };
  const disclaimerRef = React.useRef(null);
  const [mainImageIndex, setMainImageIndex] = useState(0); // Current image index
  const [isModalOpen, setModalOpen] = useState(false); // Modal state
  const [imageSize, setImageSize] = useState({ width: 200, height: 200 }); // Store image dimensions
  const [modalImageIndex, setModalImageIndex] = useState(0); // Track current modal image index
  const [isLoading, setIsLoading] = useState(false);

  const {
    vin,
    brand,
    model,
    year,
    length,
    weight,
    height,
    width,
    additionalEquipment,
    customChanges,
    personalItems,
    pickUpLocation,
    deliveryLocation,
    pickUpDate1,
    deliveryDate1,
    isInoperable,
    isTrailerEnclosed,
    senderContactName,
    senderContactPhone,
    senderContactEmail,
    recipientContactName,
    recipientContactPhone,
    recipientContactEmail,
    pickUpLocationCoordinates,
  } = order;

  const publish = () => {
    const { ...newOrder } = order;
    newOrder.year = +order.year;
    newOrder.pickUpDate2 = "1";
    newOrder.deliveryDate2 = "1";
    newOrder.assets = [];
    newOrder.statusToTMS = "sending";

    setIsLoading(true);
    dispatch(
      id ? editOrder({ id, data: newOrder }) : createOrder({ data: newOrder })
    )
      .unwrap()
      .then((response) => {
        if (id) {
          dispatch(removeOrderById(id));
        }
        setIsLoading(false);
        if (response.success === true) {
          toast.show({
            description: id
              ? "Order is updated and sent"
              : "Order is created and sent",
            ...toastSuccessConfig,
          });
        } else {
          toast.show({
            description: "Failed to send the order",
            ...toastErrorConfig,
          });
        }
        navigate("/orders");
      })
      .catch((err) => {
        setIsLoading(false);
        toast.show({
          description: "An error occurred while publishing the order",
          ...toastErrorConfig,
        });
        console.error("Error publishing order:", err);
      });
  };

  const saveDraft = () => {
    const { ...draftOrder } = order;
    draftOrder.year = +order.year;
    draftOrder.pickUpDate2 = "1";
    draftOrder.deliveryDate2 = "1";
    draftOrder.assets = [];
    draftOrder.statusToTMS = "draft";

    dispatch(
      id
        ? editOrder({ id, data: draftOrder })
        : createOrder({ data: draftOrder })
    )
      .unwrap()
      .then(() => {
        if (id) {
          return dispatch(removeOrderById(id));
        }
      })
      .then(() => {
        toast.show({
          description: `Order ${id ? "is updated and " : ""}saved as draft`,
          ...toastSuccessConfig,
        });
        navigate("/orders");
      })
      .catch((err) => {
        toast.show({
          description: "Failed to update and save draft",
          ...toastErrorConfig,
        });
      });
  };

  const prevStep = () => {
    navigate(id ? `/edit/4/${id}` : "/create/4");
  };

  const PublishOrderAlert = () => {
    const onClose = () => {
      setShownDisclaimerModal(false);
    };
    const onPublish = () => {
      setShownDisclaimerModal(false);
      publish();
    };

    return (
      <AlertDialog
        leastDestructiveRef={disclaimerRef}
        isOpen={shownDisclaimerModal}
        onClose={onClose}
        useRNModal={true}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Publish Order</AlertDialog.Header>
          <AlertDialog.Body>
            Attention! If there is no activity and confirmation of your order
            within 24 hours, your listing will be removed from the board.
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <View style={styles.buttonsRow}>
              <Button
                style={styles.cancel}
                variant="outline"
                colorScheme="coolGray"
                onPress={onClose}
                ref={disclaimerRef}
              >
                Cancel
              </Button>
              <Button style={styles.publishButton} onPress={onPublish}>
                Publish
              </Button>
            </View>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  let imagesList = [];
  if (order?.images) {
    imagesList = [...order.images];
  }
  const [mainImage, setMainImage] = useState(imagesList[0] || null);

  const Directions = () => {
    const map = useMap();
    const routesLibrary = useMapsLibrary("routes");
    const [directionsService, setDirectionService] = useState();
    const [directionsRenderer, setDirectionsRenderer] = useState();
    const [routes, setRoutes] = useState([]);

    useEffect(() => {
      if (!routesLibrary || !map) return;
      setDirectionService(new routesLibrary.DirectionsService());
      setDirectionsRenderer(new routesLibrary.DirectionsRenderer({ map }));
    }, [routesLibrary, map, pickUpLocation, deliveryLocation]);

    useEffect(() => {
      if (!directionsService || !directionsRenderer) return;
      const dirSer = new routesLibrary.DirectionsService();
      const dirRenderer = new routesLibrary.DirectionsRenderer({ map });
      // dirService
      dirSer
        .route({
          origin: pickUpLocation,
          destination: deliveryLocation,
          travelMode: google.maps.TravelMode.DRIVING,
          provideRouteAlternatives: false,
        })
        .then((response) => {
          dirRenderer.setDirections(response);
          setRoutes(response.routes);
        });
    }, [directionsService, directionsRenderer]);
  };

  const MapD = () => {
    return (
      <APIProvider apiKey={GOOGLE_API_KEY}>
        <Map
          style={{ height: 400, marginTop: 10, width: "100%" }}
          defaultCenter={position}
          defaultZoom={10}
        >
          <Directions />
        </Map>
      </APIProvider>
    );
  };

  const openModal = (index) => {
    setModalImageIndex(index); // Set the modal image index to the clicked image
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const nextModalImage = () => {
    setModalImageIndex((prevIndex) => (prevIndex + 1) % imagesList.length);
  };

  const prevModalImage = () => {
    setModalImageIndex(
      (prevIndex) => (prevIndex - 1 + imagesList.length) % imagesList.length
    );
  };

  useEffect(() => {
    Image.getSize(imageHost + mainImage, (width, height) => {
      setImageSize({
        width: Math.min(width, 600), // Max width of 800px
        height: Math.min(height, 600), // Max height of 800px
      });
    });
  }, [mainImage]);

  useEffect(() => {
    if (!active) {
      navigate("/orders");
    }
  }, []);

  return (
    <>
      <View style={styles.container}>
        {!!mainImage && (
          <Pressable
            onPress={() => {
              const selectedIndex = imagesList.findIndex(
                (img) => img === mainImage
              );
              openModal(selectedIndex);
            }}
          >
            <Image
              style={styles.image}
              height={200}
              src={imageHost + mainImage}
              alt={`Main Image ${mainImageIndex + 1}`}
            />
          </Pressable>
        )}

        {/* Thumbnails Row */}
        <ScrollView horizontal style={styles.imagesRow}>
          {imagesList.map((image, index) => (
            <Pressable onPress={() => setMainImage(image)} key={index}>
              <Image
                style={[
                  styles.imageSmall,
                  mainImage === image && styles.activeImage,
                ]}
                src={imageHost + image}
                alt={`Thumbnail ${index + 1}`}
              />
            </Pressable>
            // <Pressable key={index} onPress={() => setMainImageIndex(index)}>
            //   <Image
            //     style={[
            //       styles.imageSmall,
            //       mainImageIndex === index && styles.activeImage,
            //     ]}
            //     src={imageHost + image}
            //     alt={`Thumbnail ${index + 1}`}
            //   />
            // </Pressable>
          ))}
        </ScrollView>
        <Text style={styles.header}>
          {year} {brand} {model}
        </Text>
        {/* <Text style={styles.subheader}>{year}</Text> */}
        <View style={styles.badges}>
          <View mr={"4px"} ml={"4px"} style={styles.badge}>
            <Weight />
            <Text style={styles.badgeText}>{weight}</Text>
          </View>
          <View mr={"4px"} style={styles.badge}>
            <Length />
            <Text style={styles.badgeText}>{length}</Text>
          </View>
          <View mr={"4px"} ml={"4px"} style={styles.badge}>
            <Height />
            <Text style={styles.badgeText}>{height}</Text>
          </View>
          <View ml={"4px"} style={styles.badge}>
            <Width />
            <Text style={styles.badgeText}>{width}</Text>
          </View>
        </View>
        <View shadow="3" style={styles.vinBadge}>
          <VinGreen />
          <View style={styles.vinText}>
            <Text style={styles.greyText}>VIN</Text>
            <Text>{vin}</Text>
          </View>
        </View>
        {!!additionalEquipment?.length && (
          <>
            <Text style={styles.headerBlock}>Additional equipment</Text>
            <Text>{additionalEquipment}</Text>
          </>
        )}
        {!!customChanges?.length && (
          <>
            <Text style={styles.headerBlock}>
              Car has custom changes/tuning
            </Text>
            <Text>{customChanges}</Text>
          </>
        )}
        {!!personalItems?.length && (
          <>
            <Text style={styles.headerBlock}>Personal items</Text>
            <Text>{personalItems}</Text>
          </>
        )}
        <>
          <Text style={styles.headerBlock}>Is car inoperable?</Text>
          <Text>{isInoperable ? "Yes" : "No"}</Text>
        </>
        <>
          <Text style={styles.headerBlock}>Trailer Type</Text>
          {/* {trailerType === "closed" && (
          <View style={styles.row}>
            <TrailerClosed />
            <Text style={styles.ml4} fontSize="sm">
              Enclosed Trailer
            </Text>
          </View>
        )}
        {trailerType === "open" && (
          <View style={styles.row}>
            <TrailerOpen />
            <Text style={styles.ml4} fontSize="sm">
              Open Trailer
            </Text>
          </View>
        )} */}
          {isTrailerEnclosed ? (
            <View style={styles.row}>
              <TrailerClosed />
              <Text style={styles.ml4} fontSize="sm">
                Enclosed Trailer
              </Text>
            </View>
          ) : (
            <View style={styles.row}>
              <TrailerOpen />
              <Text style={styles.ml4} fontSize="sm">
                Open Trailer
              </Text>
            </View>
          )}
        </>
        <View style={styles.locationWrapper}>
          <LocationGreen />
          <View style={styles.locationTextWrapper}>
            <Text style={styles.locationText}>Pick Up Location</Text>
            <View style={styles.dateRow}>
              <View style={styles.addressIcon}>
                <AddressGrey />
              </View>
              <Text>{pickUpLocation}</Text>
            </View>
            <View style={styles.dateRow}>
              <View style={styles.calendarIcon}>
                <CalendarGrey />
              </View>
              <Text>{formatStringToDate(pickUpDate1)}</Text>
            </View>
          </View>
        </View>
        <View style={styles.locationWrapper}>
          <GeoGreen />
          <View style={styles.locationTextWrapper}>
            <Text style={styles.locationText}>Delivery Location</Text>
            <View style={styles.dateRow}>
              <View style={styles.addressIcon}>
                <AddressGrey />
              </View>
              <Text>{deliveryLocation}</Text>
            </View>
            <View style={styles.dateRow}>
              <View style={styles.calendarIcon}>
                <CalendarGrey />
              </View>
              <Text>{formatStringToDate(deliveryDate1)}</Text>
            </View>
          </View>
        </View>
        <MapD />
        <>
          <Text style={styles.headerBlock}>Sender information</Text>
          <View style={styles.recipientRow}>
            <GreenUser />
            <Text style={styles.locationTextWrapper}>{senderContactName}</Text>
          </View>
          <View style={styles.recipientRow}>
            <GreenEmail />
            <Text style={styles.locationTextWrapper}>{senderContactEmail}</Text>
          </View>
          <View style={styles.recipientRow}>
            <GreenPhone />
            <Text style={styles.locationTextWrapper}>{senderContactPhone}</Text>
          </View>
          <Text style={styles.headerBlock}>Recipient information</Text>
          <View style={styles.recipientRow}>
            <GreenUser />
            <Text style={styles.locationTextWrapper}>
              {recipientContactName}
            </Text>
          </View>
          <View style={styles.recipientRow}>
            <GreenEmail />
            <Text style={styles.locationTextWrapper}>
              {recipientContactEmail}
            </Text>
          </View>
          <View style={styles.recipientRow}>
            <GreenPhone />
            <Text style={styles.locationTextWrapper}>
              {recipientContactPhone}
            </Text>
          </View>
        </>
        <PublishOrderAlert />
        <View style={styles.emptySpace} />
        <BottomNavigator
          nextOnPress={() => setShownDisclaimerModal(true)}
          nextText={"Publish"}
          prevOnPress={prevStep}
          prevText={"Previous"}
          showSaveButton={true}
          saveOnPress={saveDraft}
        />
      </View>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        size="lg"
        useRNModal={true}
      >
        <Modal.Content maxWidth="90%" maxHeight="90%">
          <Modal.CloseButton />
          <View
            style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
          >
            <HStack alignItems="center" space={2}>
              {/* Previous Image Button */}
              <IconButton
                icon={<ArrowBackIcon />}
                onPress={prevModalImage}
                variant="ghost"
                isDisabled={modalImageIndex === 0} // Disable if at the first image
              />

              {/* Display the Current Modal Image */}
              <Image
                source={{ uri: imageHost + imagesList[modalImageIndex] }}
                alt={`Image ${modalImageIndex + 1}`}
                style={{
                  width: imageSize.width,
                  height: imageSize.height,
                  borderRadius: 12,
                }}
                resizeMode="contain"
              />

              {/* Next Image Button */}
              <IconButton
                icon={<ArrowForwardIcon />}
                onPress={nextModalImage}
                variant="ghost"
                isDisabled={modalImageIndex === imagesList.length - 1} // Disable if at the last image
              />
            </HStack>
          </View>
        </Modal.Content>
      </Modal>
      <Modal
        isOpen={isLoading}
        onClose={() => {}}
        size="full"
        backdropOpacity={0.9}
        useRNModal={true}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner size="lg" color="#0000ff" />
          <Text style={{ color: "#ffffff", marginTop: 10, fontSize: 20 }}>
            Order is being sent...
          </Text>
        </View>
      </Modal>
    </>
  );
}
