import * as React from "react";
import {
  Text,
  View,
  FlatList,
  AlertDialog,
  Button,
  Select,
  FormControl,
  Input,
  Pressable,
  useToast,
  Box,
  Spinner,
} from "native-base";
import { StarRatingDisplay } from "react-native-star-rating-widget";
import {
  ApplePay,
  CreditCard,
  GooglePay,
  PaymentForm,
} from "react-square-web-payments-sdk";

import styles from "./styles";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  acceptBid,
  declineBid,
  getOrderBids,
} from "../../networking/orders/ordersApi";
import { Dollar } from "../../assets/images/svg";
import { useNavigate, useParams } from "react-router-dom";
import { SquareAppId, SquareLocationId } from "../../config";
import { toastErrorConfig, toastSuccessConfig } from "../../config/constants";
import { sendCounterBid } from "../../networking/orders/ordersApi";
import { updateOrder } from "../../networking/profile/profileSlice";

export default function BidsScreen() {
  const { id } = useParams();
  const toast = useToast();
  const { orders, active } = useSelector((state) => state.profile);
  const order = orders.find((order) => order.id === id);
  const { brand, model, year } = order.vehicles[0];
  const acceptRef = React.useRef(null);
  const declineRef = React.useRef(null);
  const counterBidRef = React.useRef(null);
  const [acceptModalIsOpen, setAcceptModalIsOpen] = useState(false);
  const [declineModalIsOpen, setDeclineModalIsOpen] = useState(false);
  const [counterBidModalIsOpen, setCounterBidModalIsOpen] = useState(false);
  const [bids, setBids] = React.useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [sorting, setSorting] = useState("");
  const [currentBid, setCurrentBid] = useState({});
  const { fullName, email, phone } = useSelector((state) => state.profile);
  //const [counterPrice, setCounterPrice] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  let counterPrice = 0;

  const getBids = () => {
    setRefreshing(true);
    setSorting("");
    dispatch(getOrderBids({ id: order.id }))
      .then(({ payload }) => {
        setRefreshing(false);

        const groupedBids = Object.values(
          payload.reduce((acc, bid) => {
            const key = `${bid.orderID}-${bid.carrierID}`;
            if (!acc[key]) {
              acc[key] = { carrierName: bid.carrierName, bids: [] };
            }
            acc[key].bids.push(bid); // Collect all bids in order
            return acc;
          }, {})
        );

        // Sort each group's bids by creation date (latest last)
        groupedBids.forEach((group) => {
          group.bids.sort(
            (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
          );
        });

        setBids(groupedBids);
      })
      .catch(() => {
        setRefreshing(false);
      });
  };

  useEffect(() => {
    getBids();
  }, []);

  const sortList = [
    { name: "Price", value: "bidAmount" },
    { name: "Rating", value: "carrierRating" },
  ];

  const EmptyBids = () => {
    return (
      <View style={styles.center}>
        <Text style={styles.centerText}>Your didn't receive bids yet</Text>
      </View>
    );
  };

  const Bid = ({ group }) => {
    const { carrierName, bids } = group;
    const lastBid = bids[bids.length - 1]; // Last (latest) bid
    const previousBids = bids.slice(0, -1); // All previous bids
    const [isPreviousBidsVisible, setPreviousBidsVisible] = useState(false);

    // Check if the last bid was placed by the carrier
    const isCarrierBid = lastBid.kind === "carrierBid";
    const isAcceptedBid = lastBid.kind === "bidAcceptedByCustomer";
    const isCounterBid = lastBid.kind === "customerCounterBid";
    const isDeclinedBid = lastBid.kind === "bidDeclinedByCustomer";

    return (
      <View style={styles.bidWrapper}>
        {isCarrierBid && <Text style={styles.newBidText}>New Bid</Text>}

        <View style={styles.ratingWrapper}>
          <Text style={styles.bidName} numberOfLines={1}>
            {carrierName}
          </Text>
          {lastBid.carrierRating > 0 ? (
            <StarRatingDisplay
              starSize={20}
              starStyle={styles.starStyle}
              rating={lastBid.carrierRating}
            />
          ) : (
            <Text>No Rating</Text>
          )}
        </View>

        <View style={styles.bidPrice}>
          {isCarrierBid ? (
            <Text>Bid: ${lastBid.bidAmount}</Text>
          ) : isCounterBid ? (
            <Text>
              Counter-bid sent for ${lastBid.bidAmount}, waiting for carrier's
              response
            </Text>
          ) : isDeclinedBid ? (
            <Text>Bid from for ${lastBid.bidAmount} was declined</Text>
          ) : isAcceptedBid ? (
            <Button
              style={[styles.button, styles.acceptButton]}
              onPress={navigate(`/order/${id}`)}
            >
              <Text style={styles.buttonText}>See order</Text>
            </Button>
          ) : (
            <></>
          )}
        </View>

        {isCarrierBid ? (
          <View style={styles.buttonsWrapper}>
            <Button
              isDisabled={!active}
              variant="outline"
              onPress={() => {
                setCurrentBid(lastBid);
                setCounterBidModalIsOpen(true);
              }}
              style={[styles.button, styles.counterButton]}
            >
              <Text style={styles.counterButtonText}>Counter-bid</Text>
            </Button>
            <View style={styles.divider} />

            <Button
              isDisabled={!active}
              onPress={() => {
                setCurrentBid(lastBid);
                setAcceptModalIsOpen(true);
              }}
              style={[styles.button, styles.acceptButton]}
            >
              <Text style={styles.buttonText}>Accept</Text>
            </Button>

            <View style={styles.divider} />

            <Button
              isDisabled={!active}
              variant="fatal"
              onPress={() => {
                setCurrentBid(lastBid);
                setDeclineModalIsOpen(true);
              }}
              style={[styles.button, styles.declineButton]}
            >
              <Text style={styles.buttonText}>Decline</Text>
            </Button>
          </View>
        ) : (
          <Text style={styles.noActionText}>
            No action needed for this bid.
          </Text>
        )}

        {previousBids.length > 0 && (
          <Pressable
            onPress={() => setPreviousBidsVisible(!isPreviousBidsVisible)}
            style={styles.foldButton}
          >
            <Text style={styles.foldButtonText}>
              {isPreviousBidsVisible
                ? "Hide Previous Bids"
                : "Show Previous Bids"}
            </Text>
          </Pressable>
        )}

        {isPreviousBidsVisible && (
          <View style={styles.previousBidsWrapper}>
            {previousBids.map((prevBid, index) => (
              <View
                key={index}
                style={[
                  styles.inactiveBidWrapper,
                  prevBid.kind === "customerCounterBid" && styles.disabled,
                ]}
              >
                <Text style={styles.bidName} numberOfLines={1}>
                  Bid placed by:{" "}
                  {prevBid.kind === "customerCounterBid"
                    ? fullName
                    : carrierName}
                </Text>
                <Text style={styles.bidPrice}>Bid: ${prevBid.bidAmount}</Text>
              </View>
            ))}
          </View>
        )}
      </View>
    );
  };

  const CounterBidAlert = () => {
    const onClose = () => {
      setCounterBidModalIsOpen(false);
    };
    const onSend = () => {
      // Ensure counterPrice is a valid number
      const isValidNumber = /^\d+(\.\d+)?$/.test(counterPrice);
      if (
        !isValidNumber ||
        !(
          Number.isFinite(parseInt(counterPrice, 10)) &&
          parseInt(counterPrice, 10) > 0.01
        )
      ) {
        toast.show({
          description: "Please enter a valid counter-bid amount",
          ...toastErrorConfig,
        });
        return;
      }
      setCounterBidModalIsOpen(false);
      dispatch(
        sendCounterBid({
          data: {
            bidID: currentBid.bidID,
            carrierID: currentBid.carrierID,
            bidAmount: counterPrice,
            status: "counterBid",
            bidPlacedBy: fullName,
          },
        })
      )
        .then(() => {
          getBids();
        })
        .catch((error) => {
          toast.show({
            description:
              "An error occurred while declining the bid. Please try again.",
            ...toastErrorConfig,
          });
        });
    };
    return (
      <AlertDialog
        leastDestructiveRef={counterBidRef}
        isOpen={counterBidModalIsOpen}
        onClose={onClose}
        useRNModal={true}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Counter Bid</AlertDialog.Header>
          <AlertDialog.Body>
            Please, offer your price for the Order
            <FormControl.Label>Counter-bid</FormControl.Label>
            <Input
              onChangeText={(e) => {
                counterPrice = e;
              }}
              type="number"
              keyboardType="numeric"
              InputLeftElement={
                <View style={styles.leftIcon}>
                  <Dollar />
                </View>
              }
              placeholder="Enter price"
            />
            {/* <Input
              onChangeText={(value) => {
                // Ensure only numeric values are accepted
                let numericValue = value.replace(/[^0-9]/g, "");
                counterPrice = numericValue;
              }}
              value = {counterPrice.toString()}
              keyboardType="numeric" // Numeric keyboard for mobile devices
              placeholder="Enter price"
              InputLeftElement={
                <View style={styles.leftIcon}>
                  <Dollar />
                </View>
              }
            /> */}
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <View style={styles.buttonsRow}>
              <Button
                style={styles.cancel}
                variant="unstyled"
                colorScheme="coolGray"
                onPress={onClose}
              >
                Cancel
              </Button>
              <Button
                isDisabled={!active}
                style={styles.acButton}
                onPress={onSend}
              >
                Send
              </Button>
            </View>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  const AcceptAlert = () => {
    const [isLoading, setIsLoading] = useState(false);
    const onClose = () => {
      setAcceptModalIsOpen(false);
    };
    return (
      <AlertDialog
        leastDestructiveRef={acceptRef}
        isOpen={acceptModalIsOpen}
        onClose={onClose}
        useRNModal={true}
      >
        <AlertDialog.Content width="90%" minHeight="30%" maxHeight="90%">
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Accept Bid</AlertDialog.Header>
          <AlertDialog.Body>
            <Text>
              Are you sure you want to accept the bid? If you accept this Bid
              all other bids will be automatically declined and $
              {currentBid.bidAmount} will be charged from your card.
            </Text>
            <PaymentForm
              applicationId={SquareAppId}
              locationId={SquareLocationId}
              cardTokenizeResponseReceived={(token, verifiedBuyer) => {
                setIsLoading(true);
                dispatch(
                  acceptBid({
                    data: {
                      ...currentBid,
                      cardDetails: { nonce: token.token },
                      status: "accept",
                    },
                  })
                )
                  .then((data) => {
                    if (data?.payload?.accepted) {
                      toast.show({
                        description: "Order was paid successfully",
                        ...toastSuccessConfig,
                      });
                      setAcceptModalIsOpen(false);
                      navigate("/orders");
                    } else {
                      toast.show({
                        description:
                          "Payment Error: Unable to process your payment.",
                        ...toastErrorConfig,
                      });
                    }
                  })
                  .catch((error) => {
                    console.error("Error accepting bid:", error);
                    toast.show({
                      description:
                        "Failed to accept bid. Please check your connection and try again.",
                      ...toastErrorConfig,
                    });
                  })
                  .finally(() => setIsLoading(false)); // Always reset loading state
              }}
              createPaymentRequest={() => ({
                countryCode: "US",
                currencyCode: "USD",
                total: {
                  amount: currentBid?.bidAmount?.toString(),
                  label: currentBid?.carrierName || "",
                },
              })}
              createVerificationDetails={() => ({
                amount: currentBid?.bidAmount?.toString(),
                currencyCode: "USD",
                intent: "CHARGE",
                billingContact: {
                  familyName: fullName,
                  email: email,
                  phone: phone,
                },
              })}
            >
              <View style={{ height: 10 }} />
              <ApplePay />
              <GooglePay />
              <View style={{ height: 10 }} />
              <CreditCard
                buttonProps={{
                  css: {
                    backgroundColor: isLoading ? "#ccc" : "#1DCE80", // Disable color when loading
                    fontSize: "14px",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: isLoading ? "#ccc" : "#17b36d", // Disable hover color when loading
                    },
                  },
                  isDisabled: isLoading || !active, // Disable button when loading
                  children: isLoading ? <Spinner color="white" /> : "Pay Now",
                }}
              />
            </PaymentForm>
          </AlertDialog.Body>

          <AlertDialog.Footer>
            <View style={styles.buttonsRow}>
              <Button
                style={styles.cancel}
                variant="outline"
                colorScheme="coolGray"
                onPress={onClose}
                ref={acceptRef}
              >
                Cancel
              </Button>
            </View>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  const DeclineAlert = () => {
    const onClose = () => {
      setDeclineModalIsOpen(false);
    };
    const onDecline = () => {
      setDeclineModalIsOpen(false);
      dispatch(
        declineBid({
          data: {
            bidID: currentBid.bidID,
            // carrierID: currentBid.carrierID,
            // bidAmount: 0,
          },
        })
      ).then(() => {
        getBids();
      });
    };
    return (
      <AlertDialog
        leastDestructiveRef={declineRef}
        isOpen={declineModalIsOpen}
        onClose={onClose}
        useRNModal={true}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Decline Bid</AlertDialog.Header>
          <AlertDialog.Body>
            Are you sure you want to decline the bid?
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <View style={styles.buttonsRow}>
              <Button
                style={styles.cancel}
                variant="unstyled"
                colorScheme="coolGray"
                onPress={onClose}
                ref={declineRef}
              >
                Cancel
              </Button>
              <Button
                isDisabled={!active}
                style={styles.dcButton}
                onPress={onDecline}
              >
                Decline
              </Button>
            </View>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  const sortBy = (key) => {
    const sorted = bids.sort(function (a, b) {
      const keyA = a[key],
        keyB = b[key];
      if (keyA < keyB) {
        return key === "carrierRating" ? 1 : -1;
      }
      if (keyA > keyB) {
        return key === "carrierRating" ? -1 : 1;
      }
      return 0;
    });
    setBids([...sorted]);
  };

  return (
    <View style={styles.container}>
      <Text style={styles.pageName}>
        Bids for: {year} {brand} {model}
      </Text>
      <Box style={styles.selectContainer}>
        <Select
          minWidth="200"
          accessibilityLabel="State"
          placeholder="Sort By"
          mt="1"
          selectedValue={sorting}
          onValueChange={(itemValue) => {
            setSorting(itemValue);
            sortBy(itemValue);
          }}
          mb="5"
        >
          {sortList.map((item) => (
            <Select.Item
              key={item.value}
              label={item.name}
              value={item.value}
            />
          ))}
        </Select>
      </Box>
      {!bids?.length && !refreshing && <EmptyBids />}
      {!!bids?.length && (
        <FlatList
          refreshing={refreshing}
          data={bids}
          keyExtractor={(item, index) => `${item.carrierName}-${index}`}
          renderItem={({ item }) => <Bid group={item} />}
          onRefresh={getBids}
        />
      )}
      <AcceptAlert />
      <DeclineAlert />
      <CounterBidAlert />
    </View>
  );
}
