import { BrowserRouter, Navigate, Outlet } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import StartScreen from "../containers/StartScreen";
import AuthOptionsScreen from "../containers/AuthOptionsScreen";
import SignInScreen from "../containers/SignInScreen";
import SignUpScreen from "../containers/SignUpScreen";
import ConfirmCodeScreen from "../containers/ConfirmCodeScreen";
import Header from "../components/header";
import URFFirstScreen from "../containers/UserRegistrationFlow/FirstScreen";
import URFSecondScreen from "../containers/UserRegistrationFlow/SecondScreen";
import OrdersListScreen from "../containers/OrdersListScreen";
import OrderScreen from "../containers/OrderScreen";
import NOFFirstStepScreen from "../containers/NewOrderFlow/FirstStepScreen";
import FourthStepScreen from "../containers/NewOrderFlow/FourthStepScreen";
import PreviewScreen from "../containers/NewOrderFlow/PreviewScreen";
import SecondStepScreen from "../containers/NewOrderFlow/SecondStepScreen";
import ThirdStepScreen from "../containers/NewOrderFlow/ThirdStepScreen";
import BidsScreen from "../containers/BidsScreen";
import MyProfileScreen from "../containers/MyProfileScreen";
import EditProfileScreen from "../containers/EditProfileScreen";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ token, redirectPath = "/" }) => {
  if (!token) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

export const Router = () => {
  const token = useSelector((state) => state?.auth?.userToken);
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route element={<Header />}>
          <Route element={<ProtectedRoute token={token} />}>
            {/*Authorized users*/}
            <Route path="/orders" element={<OrdersListScreen />} />
            <Route path="/order/:id" element={<OrderScreen />} />
            <Route path="/:mode/1/:id?" element={<NOFFirstStepScreen />} />
            <Route path="/:mode/2/:id?" element={<SecondStepScreen />} />
            <Route path="/:mode/3/:id?" element={<ThirdStepScreen />} />
            <Route path="/:mode/4/:id?" element={<FourthStepScreen />} />
            <Route path="/:mode/preview/:id?" element={<PreviewScreen />} />
            <Route path="/bids/:id" element={<BidsScreen />} />
            <Route path="/profile" element={<MyProfileScreen />} />
            <Route path="/profile/edit" element={<EditProfileScreen />} />
            <Route
              path="/profile/confirm/:action/:type/:data"
              element={<ConfirmCodeScreen />}
            />
          </Route>
          <Route
            element={<ProtectedRoute token={!token} redirectPath={"/orders"} />}
          >
            {/*Non authorized users*/}
            <Route path="/" element={<StartScreen />} />
            <Route path="/signup" element={<AuthOptionsScreen />} />
            <Route path="/signin" element={<AuthOptionsScreen />} />
            <Route path="/signin/email" element={<SignInScreen />} />
            <Route path="/signin/phone" element={<SignInScreen />} />
            <Route path="/signup/email" element={<SignUpScreen />} />
            <Route path="/signup/phone" element={<SignUpScreen />} />
            <Route
              path="/confirm/:action/:type/:data"
              element={<ConfirmCodeScreen />}
            />
            <Route path="/signup/user/1" element={<URFFirstScreen />} />
            <Route path="/signup/user/2" element={<URFSecondScreen />} />
          </Route>
        </Route>
        <Route path="*" element={<p>There's nothing here: 404!</p>} />
      </Routes>
    </BrowserRouter>
  );
};
