import * as React from "react";
import {
  Button,
  FormControl,
  Input,
  View,
  Box,
  Text,
  useToast,
} from "native-base";
import styles from "./styles";
import { Email, Phone, User } from "../../../assets/images/svg";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { setUser } from "../../../networking/auth/authSlice";
import { useNavigate, useParams } from "react-router-dom";
import { toastErrorConfig } from "../../../config/constants";
import PhoneInput from "react-phone-number-input/input";

export default function URFFirstScreen({ navigation }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const { userInfo } = useSelector((state) => state.auth);
  const [phone, setPhone] = useState(userInfo.phone);
  const [email, setEmail] = useState(userInfo.email);
  const [fullName, setFullName] = useState(userInfo.fullName);
  const [isPhoneFocused, setIsPhoneFocused] = useState(false);

  const goToNextPage = () => {
    if (!email.includes("@") || !email.includes(".")) {
      toast.show({
        description: "Email is invalid",
        ...toastErrorConfig,
      });
      return;
    }

    const isValidPhone = phone && phone.replace(/\D/g, "").length === 11;

    if (!isValidPhone) {
      toast.show({
        description: "Please enter a valid 10-digit phone number.",
        ...toastErrorConfig,
      });
      return;
    }
    dispatch(
      setUser({
        email,
        phone,
        fullName,
      })
    );
    navigate("/signup/user/2");
  };
  return (
    <Box style={styles.wrapper}>
      <View style={styles.container}>
        <Text fontSize="2xl" bold textAlign={"center"}>
          Registration
        </Text>
        <FormControl>
          <FormControl.Label>Full Name</FormControl.Label>
          <Input
            value={fullName}
            onChangeText={setFullName}
            type="text"
            InputLeftElement={
              <View style={styles.leftIcon}>
                <User />
              </View>
            }
            placeholder="Full Name"
          />
          <View style={styles.divider} />
          <FormControl.Label>Email</FormControl.Label>
          <Input
            value={email}
            onChangeText={setEmail}
            textContentType="emailAddress"
            keyboardType="email-address"
            autoCapitalize="none"
            type="email"
            autoCorrect={false}
            autoCompleteType="email"
            InputLeftElement={
              <View style={styles.leftIcon}>
                <Email />
              </View>
            }
            placeholder="Email Address"
          />
          <View style={styles.divider} />
          <FormControl.Label>Phone Number</FormControl.Label>
          <View
            style={[
              {
                flexDirection: "row",
                alignItems: "center",
                borderRadius: 12,
                borderColor: isPhoneFocused ? "#1DCE80" : "#D9D9D9",
                backgroundColor: isPhoneFocused ? "#E9F8EF" : "FAFAFA",
                borderWidth: 1,
                height: 46,
                width: "100%",
                paddingHorizontal: 12,
                outline: isPhoneFocused ? "1px solid #1DCE80" : "none",
              },
            ]}
          >
            <View style={{ marginRight: 10 }}>
              <Phone />
            </View>
            <PhoneInput
              value={phone}
              onChange={setPhone}
              type="phone"
              maxLength="14"
              placeholder="(222) 333-4455"
              defaultCountry="US"
              style={{
                paddingLeft: 0,
                outline: "none",
                flex: 1,
                borderWidth: 0,
                backgroundColor: isPhoneFocused ? "#E9F8EF" : "#FAFAFA",
              }}
              onFocus={() => setIsPhoneFocused(true)}
              onBlur={() => setIsPhoneFocused(false)}
            />
          </View>
          <View style={styles.divider} />
        </FormControl>
        <Button style={styles.button} onPress={goToNextPage}>
          Continue
        </Button>
        <View style={styles.divider} />
      </View>
    </Box>
  );
}
