import * as React from "react";
import { Logo } from "../../assets/images/svg";
import styles from "./styles";
import { Button, View, Center, Pressable, Text } from "native-base";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UserProfileButton from "./userProfileButton";

export default function Header() {
  const navigate = useNavigate();
  const { userToken, name, active } = useSelector((state) => ({
    userToken: state.auth.userToken,
    name: state.profile.fullName,
    active: state.profile.active,
  }));

  return (
    <View>
      <View style={styles.header}>
        <Pressable
          onPress={() => {
            userToken ? navigate("/orders") : navigate("/");
          }}
        >
          <Logo />
        </Pressable>
        {!active && (
          <View>
            <Text color="white" fontWeight="bold" fontSize="sm">
              Your account is inactive. Please contact support.
            </Text>
          </View>
        )}
        {!!userToken && <UserProfileButton name={name} />}
      </View>
      <View style={{ marginTop: 90 }}>
        <Outlet />
      </View>
    </View>
  );
}
