import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../config";
import instance from "../axios";

const handleRejectWithValue = (error, rejectWithValue) => {
  if (error.response) {
    // Return only serializable data
    return rejectWithValue({
      status: error.response.status,
      data: error.response.data,
    });
  }
  return rejectWithValue(error.message);
};

export const createOrder = createAsyncThunk(
  "order/create",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await instance.post(`${API_URL}/orders`, data);
      return res.data;
    } catch (error) {
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const editOrder = createAsyncThunk(
  "order/edit",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const res = await instance.put(`${API_URL}/orders/${id}`, data);
      return res.data;
    } catch (error) {
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const performCancelOrder = createAsyncThunk(
  "order/cancel",
  async ({ id }, { rejectWithValue }) => {
    try {
      const res = await instance.put(`${API_URL}/orders/cancel/${id}`);
      return res.data;
    } catch (error) {
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const sendOrderToTMS = createAsyncThunk(
  "order/sendToTMS",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await instance.post(`${API_URL}/orders/sendToTMS`, data);
      return res.data;
    } catch (error) {
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const getOrderBids = createAsyncThunk(
  "bids/get",
  async ({ id }, { rejectWithValue }) => {
    try {
      const res = await instance.get(`${API_URL}/orders/${id}/bids`);
      return res.data;
    } catch (error) {
      console.log("error", error);
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const getInfoByVinNumber = createAsyncThunk(
  "vin/get",
  async ({ vin }, { rejectWithValue }) => {
    try {
      const res = await instance.get(`${API_URL}/orders/vin/${vin}`);
      return res.data;
    } catch (error) {
      console.log("error", error);
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const deleteOrder = createAsyncThunk(
  "order/delete",
  async ({ id }, { rejectWithValue }) => {
    try {
      const res = await instance.delete(`${API_URL}/orders/${id}`);
      return res.data;
    } catch (error) {
      console.log("error", error);
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const sendCounterBid = createAsyncThunk(
  "bids/counterbid",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await instance.post(
        `${API_URL}/bids/counterbid/${data.bidID}`,
        data
      );
      return res.data;
    } catch (error) {
      console.log("error", error);
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const declineBid = createAsyncThunk(
  "bids/decline",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await instance.post(
        `${API_URL}/bids/decline/${data.bidID}`,
        data
      );
      return res.data;
    } catch (error) {
      console.log("error", error);
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const disputeOrder = createAsyncThunk(
  "order/dispute",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await instance.post(
        `${API_URL}/orders/dispute/${data.id}`,
        data
      );
      return res.data;
    } catch (error) {
      console.log("error", error);
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const completeOrder = createAsyncThunk(
  "order/complete",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await instance.post(`${API_URL}/orders/complete/${data.id}`);
      return res.data;
    } catch (error) {
      console.log("error", error);
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const rateOrder = createAsyncThunk(
  "order/rate",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await instance.post(
        `${API_URL}/orders/rate/${data.id}`,
        data
      );
      return res.data;
    } catch (error) {
      console.log("error", error);
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const acceptBid = createAsyncThunk(
  "order/accept",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await instance.post(
        `${API_URL}/bids/accept/${data.id}`,
        data
      );
      return res.data;
    } catch (error) {
      console.log("error", error);
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const sendEmail = createAsyncThunk(
  "order/sendEmail",
  async (data, { rejectWithValue }) => {
    try {
      const res = await instance.post(`${API_URL}/sendgrid/sendemail`, data);
      return res.data;
    } catch {
      console.log("error", error);
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const sendSMS = createAsyncThunk(
  "order/sendSMS",
  async (data, { rejectWithValue }) => {
    try {
      const res = await instance.post(`${API_URL}/verification/sendsms`, data);
      return res.data;
    } catch {
      console.log("error", error);
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const cancelOrderWithFee = createAsyncThunk(
  "order/cancelWithFee",
  async (orderId, { rejectWithValue }) => {
    try {
      const res = await instance.post(
        `${API_URL}/orders/cancelWithFee/${orderId}`
      );
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const cancelOrderWithoutFee = createAsyncThunk(
  "order/cancelWithoutFee",
  async (orderId, { rejectWithValue }) => {
    try {
      const res = await instance.post(
        `${API_URL}/orders/cancelWithoutFee/${orderId}`
      );
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const setCarrierProblemStatus = createAsyncThunk(
  "order/setCarrierProblemStatus",
  async (orderId, { rejectWithValue }) => {
    try {
      const res = await instance.post(
        `${API_URL}/orders/carrierProblem/${orderId}`
      );
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
