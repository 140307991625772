import * as React from "react";
import {
  Center,
  Checkbox,
  FormControl,
  Input,
  Button,
  Select,
  Spinner,
  Text,
  TextArea,
  View,
  useToast,
  ScrollView,
  Pressable,
} from "native-base";
import styles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import {
  setInputValue,
  clearInputValues,
} from "../../../networking/orders/ordersSlice";
import {
  Vin,
  Model,
  Year,
  Length,
  Height,
  Width,
  CheckboxOn,
  CheckboxOff,
  TrailerClosed,
  TrailerOpen,
  Scanner,
  Weight,
  Upload,
} from "../../../assets/images/svg";
import { carsList, carsObj } from "../../../assets/data/carsList";
import { useEffect, useState } from "react";
import instance from "../../../networking/axios";
import { API_URL } from "../../../config";
import { useNavigate, useParams } from "react-router-dom";
import BottomNavigator from "../../../components/BottomNavigator";
import ReactImageUploading from "react-images-uploading";
import { toastErrorConfig } from "../../../config/constants";
import { imageHost } from "../../../config";
import Stepper from "../../../components/stepper";

export default function NOFFirstStepScreen() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const orderState = useSelector((state) => state.order);
  const {
    vin,
    brand,
    model,
    year,
    height,
    length,
    width,
    weight,
    isInoperable,
    isTrailerEnclosed,
    images,
    personalItems,
    havePersonal,
    hasCustom,
    customChanges,
    additionalEquipment,
  } = orderState;
  const toast = useToast();
  const [models, setModels] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [isSearchButtonHovered, setIsSearchButtonHovered] = useState(false);
  const { orders, active } = useSelector((state) => state.profile);
  const profileOrder = orders.find((order) => order.id === id) || null;

  let vehicle;

  if (profileOrder) {
    vehicle = profileOrder.vehicles[0] || null;
  }

  useEffect(() => {
    if (id && profileOrder && !orderState.isFetched) {
      if (!active) {
        navigate("/orders");
      }
      dispatch(clearInputValues());

      // Set general order fields
      Object.entries(profileOrder).forEach(([key, value]) => {
        if (key !== "vehicles") {
          dispatch(setInputValue({ key, value }));
        }
      });

      // Set vehicle fields and images from profileOrder
      if (profileOrder.vehicles?.length) {
        Object.entries(vehicle).forEach(([key, value]) => {
          dispatch(setInputValue({ key, value }));
        });

        if (vehicle.images?.length) {
          dispatch(setInputValue({ key: "images", value: vehicle.images }));
        }
        dispatch(setInputValue({ key: "isFetched", value: true }));
      }
    }
  }, [id, profileOrder, dispatch]);

  const createYearArray = () => {
    const currentYear = new Date().getFullYear();
    const startYear = 1900;
    const yearArray = [];
    for (let year = startYear; year <= currentYear; year++) {
      yearArray.push(year.toString());
    }

    return yearArray.reverse();
  };

  const years = createYearArray();

  const nextStep = () => {
    if (!vin) {
      toast.show({
        description: "Please fill in VIN",
        ...toastErrorConfig,
      });
      return;
    }
    if (!brand) {
      toast.show({
        description: "Please fill in Make",
        ...toastErrorConfig,
      });
      return;
    }
    if (!model) {
      toast.show({
        description: "Please fill in Model",
        ...toastErrorConfig,
      });
      return;
    }
    if (!year) {
      toast.show({
        description: "Please fill in Year",
        ...toastErrorConfig,
      });
      return;
    }
    if (images.length === 0) {
      toast.show({
        description: "Please upload at least one image.",
        ...toastErrorConfig,
      });
      return;
    }
    navigate(id ? `/edit/2/${id}` : "/create/2");
  };

  const cancel = () => {
    navigate("/orders");
  };

  const changeModel = (value) => {
    setModels(carsObj[value]);
    handleChange("brand", value);
  };

  const onChange = async (imageList) => {
    // Use Promise.all to upload images in parallel

    const uploadPromises = imageList
      .filter((image) => typeof image !== "string") // Ignore already-uploaded files
      .map((image) => uploadResource(image)); // Only upload new files
    try {
      const uploadedImages = await Promise.all(uploadPromises);

      const newImages = uploadedImages.filter((img) => img !== null);

      // Update Redux state with all successfully uploaded images
      dispatch(
        setInputValue({
          key: "images",
          value: [...images, ...newImages],
        })
      );
    } catch (error) {
      console.error("Error uploading multiple images: ", error);
    }
  };

  const uploadResource = async ({ file }) => {
    try {
      const formattedFileName = file.name.replace(/\s+/g, "-"); // Format the file name

      const formData = new FormData();
      formData.append("file", file, formattedFileName);

      const result = await instance.post(`${API_URL}/orders/upload`, formData);

      if (result?.data?.fileName) {
        // Return the full image URL for Redux state update
        return `${result.data.fileName}`;
      }
      return null;
    } catch (error) {
      console.error("Error uploading image: ", error);
      return null; // Return null to indicate failure
    }
  };

  const handleChange = (key, value) => {
    dispatch(setInputValue({ key, value }));
  };

  const getByVin = async (num) => {
    if (num?.length !== 17) {
      toast.show({
        description: "VIN is invalid",
        ...toastErrorConfig,
      });
      return;
    }
    setShowLoader(true);
    try {
      const res = await instance.get(API_URL + "/orders/vin/" + num);
      if (res?.data) {
        handleChange("brand", res?.data?.brand || "");
        handleChange("model", res?.data?.model || "");
        handleChange("height", res?.data?.height || "");
        handleChange("width", res?.data?.width || "");
        handleChange("weight", res?.data?.weight || "");
        handleChange("length", res?.data?.length || "");
        handleChange("year", res?.data?.year || "");
      }
    } catch (error) {
      toast.show({
        description: "Unable to retrieve car info using the provided VIN",
        ...toastErrorConfig,
      });
      setShowLoader(false);
      return;
    } finally {
      setShowLoader(false);
    }
  };

  const BrandSelect = () => {
    return (
      <Select
        InputLeftElement={
          <View style={styles.leftIcon}>
            <Model />
          </View>
        }
        selectedValue={brand}
        onValueChange={changeModel}
        minWidth="200"
        accessibilityLabel="brand"
        placeholder="Select"
        mt="1"
      >
        {carsList.map((car) => (
          <Select.Item key={car} label={car} value={car} />
        ))}
      </Select>
    );
  };

  const ModelSelect = () => {
    return (
      <Select
        isDisabled={!brand?.length}
        onValueChange={(value) => {
          handleChange("model", value);
        }}
        selectedValue={model}
        InputLeftElement={
          <View style={styles.leftIcon}>
            <Model />
          </View>
        }
        minWidth="200"
        accessibilityLabel="models"
        placeholder="Select"
        mt="1"
      >
        {models.map((model) => (
          <Select.Item key={model} label={model} value={model} />
        ))}
        {models.length === 0 && !!model && (
          <Select.Item key={model} label={model} value={model} />
        )}
      </Select>
    );
  };

  const YearSelect = () => {
    return (
      <Select
        selectedValue={year}
        onValueChange={(value) => {
          handleChange("year", value);
        }}
        InputLeftElement={
          <View style={styles.leftIcon}>
            <Year />
          </View>
        }
        minWidth="200"
        accessibilityLabel="year"
        placeholder="Select"
        mt="1"
      >
        {years.map((year) => (
          <Select.Item key={year} label={year} value={year} />
        ))}
      </Select>
    );
  };

  return (
    <View style={styles.container}>
      <Text style={styles.h1}>New Order</Text>
      <View style={styles.stepper}>
        <Stepper currentStep={1} />
      </View>
      <View>
        {showLoader && (
          <View
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              position: "absolute",
              width: "100%",
              height: "100%",
              zIndex: 10,
              paddingTop: 200,
            }}
          >
            <Spinner size="lg" />
          </View>
        )}
        <View style={styles.divider} />
        <View style={styles.inputsGrid}>
          <View style={styles.inputGrid}>
            <FormControl.Label
              style={{
                marginBottom: 0,
                paddingBottom: 0,
              }}
            >
              VIN
            </FormControl.Label>
            <View>
              <Input
                autoCapitalize={"characters"}
                type="text"
                value={vin}
                onChangeText={(value) => {
                  // Only allow English letters and numbers, and convert to uppercase
                  const sanitizedValue = value
                    .replace(/[^A-Z0-9]/gi, "")
                    .toUpperCase();
                  handleChange("vin", sanitizedValue);
                }}
                onBlur={(e) => {
                  getByVin(e?.nativeEvent?.text || "");
                }}
                InputLeftElement={
                  <View style={styles.leftIcon}>
                    <Vin />
                  </View>
                }
                InputRightElement={
                  <View
                    onMouseEnter={() => setIsSearchButtonHovered(true)}
                    onMouseLeave={() => setIsSearchButtonHovered(false)}
                    style={[
                      styles.searchButton,
                      isSearchButtonHovered && styles.searchButtonHover,
                    ]}
                  >
                    <Pressable onPress={() => getByVin(vin)}>
                      <Text style={styles.searchButtonText}>Search</Text>
                    </Pressable>
                  </View>
                }
                placeholder="VIN"
              />
            </View>
          </View>
          <View style={styles.inputGrid}>
            <FormControl.Label>Make</FormControl.Label>
            <BrandSelect />
          </View>
          <View style={styles.inputGrid}>
            <FormControl.Label>Model</FormControl.Label>
            <ModelSelect />
          </View>
          <View style={styles.inputGrid}>
            <FormControl.Label>Year</FormControl.Label>
            <YearSelect />
          </View>
          <View style={styles.inputGrid}>
            <FormControl.Label>Weight</FormControl.Label>
            <Input
              value={weight}
              onChangeText={(value) => {
                handleChange("weight", value);
              }}
              type="text"
              InputLeftElement={
                <View style={styles.leftIcon}>
                  <Weight />
                </View>
              }
              placeholder="Enter weight"
            />
          </View>
          <View style={styles.inputGrid}>
            <FormControl.Label>Length</FormControl.Label>
            <Input
              value={length}
              onChangeText={(value) => {
                dispatch(setInputValue({ key: "length", value }));
              }}
              type="text"
              InputLeftElement={
                <View style={styles.leftIcon}>
                  <Length />
                </View>
              }
              placeholder="Enter length"
            />
          </View>
          <View style={styles.inputGrid}>
            <FormControl.Label>Height</FormControl.Label>
            <Input
              value={height}
              onChangeText={(value) => {
                handleChange("height", value);
              }}
              type="text"
              InputLeftElement={
                <View style={styles.leftIcon}>
                  <Height />
                </View>
              }
              placeholder="Enter height"
            />
          </View>
          <View style={styles.inputGrid}>
            <FormControl.Label>Width</FormControl.Label>
            <Input
              value={width}
              onChangeText={(value) => {
                handleChange("width", value);
              }}
              type="text"
              InputLeftElement={
                <View style={styles.leftIcon}>
                  <Width />
                </View>
              }
              placeholder="Enter width"
            />
          </View>
        </View>
        <View style={styles.divider} />
        <View style={styles.row}>
          <Checkbox
            value="checkbox"
            defaultIsChecked={isInoperable}
            onChange={() => handleChange("isInoperable", !isInoperable)}
            accessibilityLabel="checkbox"
          />
          <Text style={styles.textContainer} fontSize="sm">
            Is car inoperable?
          </Text>
        </View>
        <View style={styles.divider} />
        <View style={styles.row}>
          <Checkbox
            value="checkbox"
            defaultIsChecked={isTrailerEnclosed}
            onChange={() =>
              handleChange("isTrailerEnclosed", !isTrailerEnclosed)
            }
            accessibilityLabel="checkbox"
          />
          <Text style={styles.textContainer} fontSize="sm">
            Do you need enslosed Trailer?
          </Text>
        </View>
        <View style={styles.divider} />
        <FormControl.Label>Additional equipment</FormControl.Label>
        <TextArea
          value={additionalEquipment}
          onChangeText={(value) => {
            handleChange("additionalEquipment", value);
          }}
          numberOfLines={2}
          placeholder="Enter additional equipment"
        />
        <View style={styles.divider} />
        <>
          <View style={styles.row}>
            <Center>
              <Checkbox
                value="checkbox"
                isChecked={hasCustom}
                onChange={(value) => {
                  handleChange("hasCustom", value);
                  if (!value) {
                    handleChange("customChanges", ""); // Clear the custom changes field when unchecked
                  }
                }}
                accessibilityLabel="checkbox"
              />
            </Center>

            <Text style={styles.textContainer} fontSize="sm">
              Car has custom changes/tuning
            </Text>
          </View>
          {hasCustom && (
            <>
              <View style={styles.divider} />
              <TextArea
                value={customChanges}
                onChangeText={(value) => {
                  handleChange("customChanges", value);
                }}
                numberOfLines={2}
                placeholder="Please, describe the changes added in the construction"
              />
            </>
          )}
        </>
        <View style={styles.divider} />
        <>
          <View style={styles.row}>
            <Center>
              <Checkbox
                value="checkbox"
                isChecked={havePersonal}
                onChange={(value) => {
                  handleChange("havePersonal", value);
                  if (!value) {
                    handleChange("personalItems", ""); // Clear the personal items field when unchecked
                  }
                }}
                accessibilityLabel="checkbox"
              />
            </Center>

            <Text style={styles.textContainer} fontSize="sm">
              I have personal items in my car
            </Text>
          </View>
          {havePersonal && (
            <>
              <View style={styles.divider} />
              <TextArea
                value={personalItems}
                onChangeText={(value) => {
                  handleChange("personalItems", value);
                }}
                numberOfLines={2}
                placeholder="Please, list the items to be transported and indicate their total weight"
              />
            </>
          )}
        </>
        <View style={styles.divider} />
        <ReactImageUploading
          multiple
          value={images}
          onChange={onChange}
          maxNumber={10}
          dataURLKey="data_url"
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
          }) => (
            <div className="upload__image-wrapper">
              <View style={{ marginBottom: 10 }}>
                <ScrollView
                  horizontal
                  showsHorizontalScrollIndicator={false}
                  style={{ flexDirection: "row" }}
                >
                  {imageList.map((image, index) => {
                    const imageUrl = `${image.fileName || image}`;
                    return (
                      <div
                        key={index}
                        style={{ marginTop: 10, marginRight: 10 }}
                      >
                        <img
                          src={`${imageHost}${imageUrl}`}
                          alt={`uploaded-${index}`}
                          width="100"
                          height="100"
                          style={{
                            width: "200px",
                            height: "200px",
                            objectFit: "cover",
                            borderRadius: 12,
                            filter: "blur(0.5px)", // Helps with display on smaller resolutions
                          }}
                        />
                        <div style={{ textAlign: "center" }}>
                          <button
                            onClick={() => {
                              const newImageList = images.filter(
                                (_, i) => i !== index
                              ); // Filter out the removed image
                              handleChange("images", newImageList); // Dispatch the new state to Redux
                            }}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </ScrollView>
              </View>
              <div
                style={styles.dragImages}
                onClick={onImageUpload}
                {...dragProps}
              >
                <Upload />
                <div style={styles.uploadText}>Drag & drop files or Browse</div>
                <div style={styles.supportedText}>
                  Supported formats: JPEG, PNG
                </div>
              </div>
            </div>
          )}
        </ReactImageUploading>
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 10,
            marginTop: 15,
          }}
        >
          {/*{assets.map((image, index) => (*/}
          {/*  <TouchableOpacity onPress={() => {}} key={index}>*/}
          {/*    <Image*/}
          {/*      alt={image.uri}*/}
          {/*      style={styles.image}*/}
          {/*      height={66}*/}
          {/*      width={66}*/}
          {/*      src={image.uri}*/}
          {/*    />*/}
          {/*  </TouchableOpacity>*/}
          {/*))}*/}
        </View>
        <View style={styles.emptySpace} />
        <BottomNavigator
          nextOnPress={nextStep}
          nextText={"Next"}
          prevOnPress={cancel}
          prevText={"Cancel"}
        />
      </View>
    </View>
  );
}
