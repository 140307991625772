import { createSlice } from "@reduxjs/toolkit";

export const orderSlice = createSlice({
  name: "order",
  initialState: {
    vin: "",
    additionalEquipment: "",
    hasCustom: false,
    havePersonal: false,
    flexiblePickupDate: false,
    flexibleDeliveryDate: false,
    images: [],
    brand: "",
    model: "",
    year: "",
    senderContactName: "",
    senderContactPhone: "",
    senderContactEmail: "",
    recipientContactName: "",
    recipientContactEmail: "",
    recipientContactPhone: "",
    // length: '',
    // height: '',
    // width: '',
    customChanges: "",
    personalItems: "",
    pickUpLocation: "",
    deliveryLocation: "",
    pickUpDate1: "",
    deliveryDate1: "",
    isInoperable: false,
    isTrailerEnclosed: false,
    bidAmount: "",
    carrierEmail: "",
    carrierName: "",
    carrierRating: "",
    carrierPhone: "",
    carrierSince: "",
    numberOfTrucks: "",
    isFetched: false,
  },
  reducers: {
    setInputValue: (state, action) => {
      const { key, value } = action.payload;
      if (!key.includes("PIN")) {
        state[key] = value;
      }
    },
    clearInputValues: (state) => {
      Object.keys(state).forEach((key) => {
        if (typeof state[key] === "boolean") {
          state[key] = false; // Reset boolean values to false
        } else if (Array.isArray(state[key])) {
          state[key] = []; // Reset arrays to empty arrays
        } else {
          state[key] = ""; // Reset other types (e.g., strings) to empty strings
        }
      });
    },
  },
});

export const { setInputValue, clearInputValues } = orderSlice.actions;
export const selectInputValues = (state) => state.order;
export default orderSlice.reducer;
