import * as React from "react";
import styles from "./styles";
import { Text, View } from "native-base";

export default function BidStatus({ status }) {
  const textMapper = {
    notSent: "New order",
    new: "Draft order",
    auction: "Bid auction",
    waitingPickUp: "Waiting for pickup",
    pickedUp: "Picked Up",
    delivered: "Delivered",
    completed: "Completed",
    completedAndRated: "Feedback received",
    dispute: "In dispute",
    carrierProblem: "Investigating"
  };
  const colorMapper = {
    auction: "#1DCE80",
    waitingPickUp: "#EA580C",
    pickedUp: "#EA580C",
    delivered: "#166534",
    completed: "#166534",
    dispute: "#E11D48",
  };
  return (
    <View style={styles.statusRow}>
      <View
        style={[
          styles.status,
          { backgroundColor: colorMapper[status] || "#000000" },
        ]}
      />
      <Text style={styles.bidText}>{textMapper[status] || status}</Text>
    </View>
  );
}
