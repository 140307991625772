import * as React from "react";
import {
  Button,
  Center,
  Checkbox,
  FormControl,
  Input,
  Text,
  View,
  useToast,
} from "native-base";
import styles from "./styles";
import { Email, Phone, User } from "../../../assets/images/svg";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { carsObj } from "../../../assets/data/carsList";
import { setInputValue } from "../../../networking/orders/ordersSlice";
import { useNavigate, useParams } from "react-router-dom";
import BottomNavigator from "../../../components/BottomNavigator";
import PhoneInput from "react-phone-number-input/input";
import { toastErrorConfig } from "../../../config/constants";
import Stepper from "../../../components/stepper";

export default function NOFFourthStepScreen() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSenderPhoneFocused, setIsSenderPhoneFocused] = useState(false);
  const [isRecipientPhoneFocused, setIsRecipientPhoneFocused] = useState(false);
  const toast = useToast();

  const {
    recipientContactName,
    recipientContactPhone,
    recipientContactEmail,
    senderContactName,
    senderContactPhone,
    senderContactEmail,
    checkboxRecipient,
    checkboxSender,
    email,
    fullName,
    phone,
  } = useSelector((state) => ({
    recipientContactName: state.order.recipientContactName,
    recipientContactPhone: state.order.recipientContactPhone,
    recipientContactEmail: state.order.recipientContactEmail,
    senderContactName: state.order.senderContactName,
    senderContactPhone: state.order.senderContactPhone,
    senderContactEmail: state.order.senderContactEmail,
    checkboxRecipient: state.order.checkboxRecipient,
    checkboxSender: state.order.checkboxSender,
    email: state.profile.email,
    fullName: state.profile.fullName,
    phone: state.profile.phone,
  }));

  const nextStep = () => {
    if (!senderContactName || !senderContactPhone || !senderContactEmail) {
      toast.show({
        description: "Please fill in sender information to proceed",
        ...toastErrorConfig,
      });
      return;
    }
    if (
      !recipientContactName ||
      !recipientContactEmail ||
      !recipientContactPhone
    ) {
      toast.show({
        description: "Please fill in recipient information to proceed",
        ...toastErrorConfig,
      });
      return;
    }

    if (
      !senderContactEmail.includes("@") ||
      !senderContactEmail.includes(".")
    ) {
      toast.show({
        description: "Sender email is invalid",
        ...toastErrorConfig,
      });
      return;
    }

    if (
      !recipientContactEmail.includes("@") ||
      !recipientContactEmail.includes(".")
    ) {
      toast.show({
        description: "Recipient email is invalid",
        ...toastErrorConfig,
      });
      return;
    }

    const isValidSenderPhone =
      senderContactPhone && senderContactPhone.replace(/\D/g, "").length === 11;

    if (!isValidSenderPhone) {
      toast.show({
        description: "Please enter a valid 10-digit sender's phone number",
        ...toastErrorConfig,
      });
      return;
    }

    const isValidRecipientPhone =
      recipientContactPhone &&
      recipientContactPhone.replace(/\D/g, "").length === 11;

    if (!isValidRecipientPhone) {
      toast.show({
        description: "Please enter a valid 10-digit recipient's phone number",
        ...toastErrorConfig,
      });
      return;
    }

    navigate(id ? `/edit/preview/${id}` : "/create/preview");
  };

  const prevStep = () => {
    navigate(id ? `/edit/3/${id}` : "/create/3");
  };

  return (
    <View style={styles.container}>
      <Text style={styles.h1}>New Order</Text>
      <View style={styles.stepper}>
        <Stepper currentStep={4} />
      </View>
      <View>
        <Text
          style={[styles.sectionTitle, { fontWeight: "bold", fontSize: 18 }]}
        >
          Sender information
        </Text>
        <View style={styles.row}>
          <Center>
            <Checkbox
              value="checkbox"
              defaultIsChecked={checkboxSender}
              onChange={(state) => {
                dispatch(
                  setInputValue({ key: "checkboxSender", value: state })
                );
                if (state) {
                  dispatch(
                    setInputValue({
                      key: "senderContactName",
                      value: fullName,
                    })
                  );
                  dispatch(
                    setInputValue({
                      key: "senderContactEmail",
                      value: email,
                    })
                  );
                  dispatch(
                    setInputValue({
                      key: "senderContactPhone",
                      value: phone,
                    })
                  );
                } else {
                  dispatch(
                    setInputValue({ key: "senderContactName", value: "" })
                  );
                  dispatch(
                    setInputValue({ key: "senderContactEmail", value: "" })
                  );
                  dispatch(
                    setInputValue({ key: "senderContactPhone", value: "" })
                  );
                }
              }}
              accessibilityLabel="checkbox"
            />
          </Center>

          <Text style={styles.textContainer} fontSize="sm">
            Will the account owner be the sender?
          </Text>
        </View>
        <FormControl.Label>Sender Name</FormControl.Label>
        <Input
          value={senderContactName}
          onChangeText={(value) => {
            dispatch(setInputValue({ key: "senderContactName", value }));
          }}
          type="text"
          isDisabled={checkboxSender}
          InputLeftElement={
            <View style={styles.leftIcon}>
              <User />
            </View>
          }
          placeholder="Full Name"
        />
        <View style={styles.divider} />
        <FormControl.Label>Sender Email</FormControl.Label>
        <Input
          value={senderContactEmail}
          onChangeText={(value) => {
            dispatch(setInputValue({ key: "senderContactEmail", value }));
          }}
          textContentType="emailAddress"
          keyboardType="email-address"
          autoCapitalize="none"
          type="email"
          autoCorrect={false}
          autoCompleteType="email"
          isDisabled={checkboxSender}
          InputLeftElement={
            <View style={styles.leftIcon}>
              <Email />
            </View>
          }
          placeholder="Email Address"
        />
        <View style={styles.divider} />
        <FormControl.Label>Sender Phone Number</FormControl.Label>
        <View
          style={[
            {
              flexDirection: "row",
              alignItems: "center",
              borderRadius: 12,
              borderColor: isSenderPhoneFocused ? "#1DCE80" : "#D9D9D9",
              backgroundColor: isSenderPhoneFocused ? "#E9F8EF" : "FAFAFA",
              borderWidth: 1,
              height: 46,
              width: "100%",
              paddingHorizontal: 12,
              outline: isSenderPhoneFocused ? "1px solid #1DCE80" : "none",
              opacity: checkboxSender ? 0.5 : 1, 
            },
          ]}
        >
          <View style={{ marginRight: 10 }}>
            <Phone />
          </View>
          <PhoneInput
            value={senderContactPhone}
            onChange={(value) => {
              dispatch(setInputValue({ key: "senderContactPhone", value }));
            }}
            type="phone"
            maxLength="14"
            placeholder="(222) 333-4455"
            defaultCountry="US"
            style={{
              paddingLeft: 0,
              outline: "none",
              flex: 1,
              borderWidth: 0,
              backgroundColor: isSenderPhoneFocused ? "#E9F8EF" : "white",
            }}
            onFocus={() => setIsSenderPhoneFocused(true)}
            onBlur={() => setIsSenderPhoneFocused(false)}
            disabled={checkboxSender}
          />
        </View>
        <View style={styles.divider} />
        <View style={styles.divider} />
        <View style={styles.divider} />
        <Text
          style={[styles.sectionTitle, { fontWeight: "bold", fontSize: 18 }]}
        >
          Recipient information
        </Text>
        <View style={styles.row}>
          <Center>
            <Checkbox
              value="checkbox"
              defaultIsChecked={checkboxRecipient}
              onChange={(state) => {
                dispatch(
                  setInputValue({ key: "checkboxRecipient", value: state })
                );
                if (state) {
                  dispatch(
                    setInputValue({
                      key: "recipientContactName",
                      value: fullName,
                    })
                  );
                  dispatch(
                    setInputValue({
                      key: "recipientContactEmail",
                      value: email,
                    })
                  );
                  dispatch(
                    setInputValue({
                      key: "recipientContactPhone",
                      value: phone,
                    })
                  );
                } else {
                  dispatch(
                    setInputValue({ key: "recipientContactName", value: "" })
                  );
                  dispatch(
                    setInputValue({ key: "recipientContactEmail", value: "" })
                  );
                  dispatch(
                    setInputValue({ key: "recipientContactPhone", value: "" })
                  );
                }
              }}
              accessibilityLabel="checkbox"
            />
          </Center>

          <Text style={styles.textContainer} fontSize="sm">
            Will the account owner be the recipient?
          </Text>
        </View>
        <FormControl.Label>Recipient Name</FormControl.Label>
        <Input
          value={recipientContactName}
          onChangeText={(value) => {
            dispatch(setInputValue({ key: "recipientContactName", value }));
          }}
          type="text"
          isDisabled={checkboxRecipient}
          InputLeftElement={
            <View style={styles.leftIcon}>
              <User />
            </View>
          }
          placeholder="Full Name"
        />
        <View style={styles.divider} />
        <FormControl.Label>Recipient Email</FormControl.Label>
        <Input
          value={recipientContactEmail}
          onChangeText={(value) => {
            dispatch(setInputValue({ key: "recipientContactEmail", value }));
          }}
          textContentType="emailAddress"
          keyboardType="email-address"
          autoCapitalize="none"
          type="email"
          isDisabled={checkboxRecipient}
          autoCorrect={false}
          autoCompleteType="email"
          InputLeftElement={
            <View style={styles.leftIcon}>
              <Email />
            </View>
          }
          placeholder="Email Address"
        />
        <View style={styles.divider} />
        <FormControl.Label>Recipient Phone Number</FormControl.Label>
        <View
          style={[
            {
              flexDirection: "row",
              alignItems: "center",
              borderRadius: 12,
              borderColor: isRecipientPhoneFocused ? "#1DCE80" : "#D9D9D9",
              backgroundColor: isRecipientPhoneFocused ? "#E9F8EF" : "FAFAFA",
              borderWidth: 1,
              height: 46,
              width: "100%",
              paddingHorizontal: 12,
              outline: isRecipientPhoneFocused ? "1px solid #1DCE80" : "none",
              opacity: checkboxRecipient ? 0.5 : 1, 
            },
          ]}
        >
          <View style={{ marginRight: 10 }}>
            <Phone />
          </View>
          <PhoneInput
            value={recipientContactPhone}
            onChange={(value) => {
              dispatch(setInputValue({ key: "recipientContactPhone", value }));
            }}
            type="phone"
            maxLength="14"
            placeholder="(222) 333-4455"
            defaultCountry="US"
            style={{
              paddingLeft: 0,
              outline: "none",
              flex: 1,
              borderWidth: 0,
              backgroundColor: isRecipientPhoneFocused ? "#E9F8EF" : "white",
            }}
            onFocus={() => setIsRecipientPhoneFocused(true)}
            onBlur={() => setIsRecipientPhoneFocused(false)}
            disabled={checkboxRecipient}
          />
        </View>
        <View style={styles.emptySpace} />
        <BottomNavigator
          nextOnPress={nextStep}
          nextText={"Next"}
          prevOnPress={prevStep}
          prevText={"Previous"}
        />
      </View>
    </View>
  );
}
