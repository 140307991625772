import React from "react";
import { View, Text } from "react-native";
import Svg, { Circle, Path, Text as SvgText, Line } from "react-native-svg";
import styles from "./styles";

const steps = [
  { label: "Vehicle" },
  { label: "Locations" },
  { label: "Dates" },
  { label: "Contact" },
];

const Stepper = ({ currentStep }) => {
  return (
    <View style={styles.container}>
      {steps.map((step, index) => (
        <View key={index} style={styles.stepWrapper}>
          <Step
            isCompleted={index < currentStep - 1}
            isActive={index === currentStep - 1}
            isDisabled={index >= currentStep}
            stepNumber={index + 1}
            label={step.label}
          />
          {index < steps.length - 1 && <DashedLine />}
        </View>
      ))}
    </View>
  );
};

const Step = ({ isCompleted, isActive, isDisabled, stepNumber, label }) => (
  <View style={styles.stepContainer}>
    <View style={styles.step}>
      <Svg height="32" width="32">
        {isCompleted ? (
          <Circle cx="16" cy="16" r="14" fill="black" />
        ) : (
          <Circle
            cx="16"
            cy="16"
            r="14"
            fill="none"
            stroke={isDisabled ? "#C0C0C0" : "black"}
            strokeWidth="1"
          />
        )}
        {isCompleted ? (
          <Path
            d="M11.5 16l2.5 2.5 6-6"
            stroke="white"
            strokeWidth="2"
            fill="none"
          />
        ) : (
          <SvgText
            x="16"
            y="20"
            textAnchor="middle"
            fontWeight="bold"
            fontSize="12"
            fill={isDisabled ? "#A9A9A9" : "black"}
          >
            {stepNumber}
          </SvgText>
        )}
      </Svg>
    </View>
    <Text
      style={[styles.stepLabel, { color: isDisabled ? "#A9A9A9" : "black" }]}
    >
      {label}
    </Text>
  </View>
);

const DashedLine = () => (
  <Svg height="2" width="30" style={styles.dashedLine}>
    <Line
      x1="0"
      y1="1"
      x2="30"
      y2="1"
      stroke="#C0C0C0"
      strokeWidth="2"
      strokeDasharray="5 5"
    />
  </Svg>
);

export default Stepper;
