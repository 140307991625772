import * as React from "react";
import { Button, Text, View, useToast, Box } from "native-base";
import styles from "./styles";
import { useState } from "react";
import axios from "axios";
import { API_URL } from "../../config";
import { useNavigate, useParams } from "react-router-dom";
import OtpInput from "react-otp-input";
import { toastErrorConfig, toastSuccessConfig } from "../../config/constants";
import { useDispatch } from "react-redux";
import {
  updateProfileEmail,
  updateProfilePhone,
} from "../../networking/profile/profileApi";
import { loginUser } from "../../networking/auth/authApi";

export default function ConfirmCodeScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { type, data, action } = useParams();
  const toast = useToast();
  const [code, setCode] = useState("");

  const verifyCode = async () => {
    setCode("");
    const response = await axios
      .post(`${API_URL}/verification/verify`, {
        to: data,
        code,
      })
      .catch((err) => {
        toast.show({
          description: "Incorrect Verification Code",
          ...toastErrorConfig,
        });
      });

    if (response) {
      if (action === "update") {
        // Check if updating profile for phone or email
        if (type === "phone") {
          dispatch(updateProfilePhone({ phone: data }))
            .then((updatedProfile) => {
              toast.show({
                description: "Phone number was successfully updated",
                ...toastSuccessConfig,
              });
              navigate("/profile");
            })
            .catch((error) => {
              console.log(error);
              toast.show({
                description: "Failed to update the phone number",
                ...toastErrorConfig,
              });
            });
        } else if (type === "email") {
          dispatch(updateProfileEmail({ email: data }))
            .then((updatedProfile) => {
              toast.show({
                description: "Email was successfully updated",
                ...toastSuccessConfig,
              });
              navigate("/profile");
            })
            .catch((error) => {
              console.log(error);
              toast.show({
                description: "Failed to update the email",
                ...toastErrorConfig,
              });
            });
        }
      } else if (action === "signin") {
        const credential = type === "email" ? { email: data } : { phone: data };
        await dispatch(loginUser(credential)).unwrap();
        // toast.show({
        //   description: "Verification successful!",
        //   ...toastSuccessConfig,
        // });
        navigate("/orders");
      } else {
        toast.show({
          description: "Verification successful! Proceeding to the next step",
          ...toastSuccessConfig,
        });
        navigate("/signup/user/1");
      }
    }
  };

  const backToPrevious = () => {
    navigate(action === "update" ? "/profile" : "/signIn");
  };

  return (
    <Box style={styles.wrapper}>
      <Box style={styles.container}>
        <Text fontSize="2xl" bold pb={3} textAlign={"center"}>
          Enter verification code
        </Text>
        {type === "phone" && (
          <Text style={styles.subheader} pb={3}>
            A OTP has been sent to <Text bold> {data}</Text>. Kindly enter below
            the 6 digit code.
          </Text>
        )}
        {type === "email" && (
          <Text style={styles.subheader} pb={3}>
            Please enter the code we just sent to your email{" "}
            <Text bold>{data}</Text>
          </Text>
        )}

        <OtpInput
          value={code}
          onChange={setCode}
          numInputs={6}
          inputType="tel"
          containerStyle={{ display: "flex", justifyContent: "space-between" }}
          inputStyle={styles.inputOpt}
          shouldAutoFocus={1}
          renderInput={(props) => <input {...props} />}
        />

        <View style={{ marginTop: 20 }}>
          <Text fontSize="sm" textAlign={"center"}>
            Didn’t receive the OTP?{" "}
            <Text
              onPress={() => {
                const verificationData =
                  type === "email" ? { email: data } : { phone: data };
                axios.post(`${API_URL}/verification/${type}`, verificationData);
                toast.show({
                  description: "New code was sent",
                  ...toastSuccessConfig,
                });
                setCode("");
              }}
              style={styles.actionText}
            >
              Resend OTP
            </Text>
          </Text>
        </View>
        <View style={styles.buttonContainer}>
          <Button style={styles.button} onPress={verifyCode}>
            Continue
          </Button>
        </View>

        <Button
          textAlign={"center"}
          style={styles.actionText}
          onPress={backToPrevious}
          variant="outline"
        >
          <Text>
            {action === "update" ? "Back to Profile" : "Back to Sign In"}
          </Text>
        </Button>
      </Box>
    </Box>
  );
}
