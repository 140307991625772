import * as React from "react";
import {
  Button,
  ScrollView,
  Text,
  View,
  Image,
  AlertDialog,
  Pressable,
  Spinner,
  useToast,
  Modal,
  HStack,
  IconButton,
  ArrowBackIcon,
  ArrowForwardIcon,
  FormControl,
  TextArea,
  Center,
  Select,
} from "native-base";

import styles from "./styles";
import { useEffect, useState, useRef } from "react";
import {
  AddImage,
  CalendarGrey,
  GeoGreen,
  GreenEmail,
  GreenPhone,
  GreenUser,
  Height,
  Length,
  LocationGreen,
  TrailerClosed,
  TrailerOpen,
  VinGreen,
  Weight,
  Width,
  EyeSlash,
  Eye,
} from "../../assets/images/svg";
import AddressGrey from "../../assets/images/svg/address-grey";
import { useDispatch, useSelector } from "react-redux";
import { formatStringToDate } from "../../utils";
import BidStatus from "../../components/BidStatus";
import { useNavigate, useParams } from "react-router-dom";
import { updateOrder } from "../../networking/profile/profileSlice";
import {
  sendOrderToTMS,
  deleteOrder,
  performCancelOrder,
  completeOrder,
  disputeOrder,
  rateOrder,
  sendSMS,
  sendEmail,
} from "../../networking/orders/ordersApi";
import { toastErrorConfig, toastSuccessConfig } from "../../config/constants";
import { getOrder } from "../../networking/profile/profileApi";
import ReactImageUploading from "react-images-uploading";
import { API_URL, imageHost } from "../../config";
import instance from "../../networking/axios";
import StarRating from "react-native-star-rating-widget";
import {
  cancelOrderWithFee,
  cancelOrderWithoutFee,
  setCarrierProblemStatus,
} from "../../networking/orders/ordersApi";
import isFeeApplied from "../../utils";

export default function OrderScreen() {
  const { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const { orders, active } = useSelector((state) => state.profile);
  const order = orders.find((order) => order.id === id);
  const dispatch = useDispatch();
  const [shownDeleteModal, setShownDeleteModal] = useState(false);
  const cancelRef = useRef(null);
  const [mainImageIndex, setMainImageIndex] = useState(0); // Track current image index
  const [imageSize, setImageSize] = useState({ width: 200, height: 200 }); // Store image dimensions
  const [modalImageIndex, setModalImageIndex] = useState(0); // Track current modal image index
  const [isLoading, setIsLoading] = useState(false);
  const [completeModalIsOpen, setCompleteModalIsOpen] = useState(false);
  const [disputeModalIsOpen, setDisputeModalIsOpen] = useState(false);
  const [rateModalIsOpen, setRateModalIsOpen] = useState(false);
  const rateRef = React.useRef(null);
  const [selectReasonModalOpen, setSelectReasonModalOpen] = useState(false);
  const [cancelWithoutFeeAlertOpen, setCancelWithoutFeeAlertOpen] =
    useState(false);
  const [cancelWithFeeAlertOpen, setCancelWithFeeAlertOpen] = useState(false);
  const [isSendInfoModalOpen, setSendInfoModalOpen] = useState(false);
  const [infoType, setInfoType] = useState("");

  if (!order) {
    return <View />;
  }
  const {
    vehicles,
    pickUpLocation,
    deliveryLocation,
    pickUpDate1,
    deliveryDate1,
    status,
    senderContactName,
    senderContactPhone,
    senderContactEmail,
    recipientContactName,
    recipientContactEmail,
    recipientContactPhone,
    bidAmount,
    carrierEmail,
    carrierName,
    carrierPhone,
    carrierSince,
    numberOfTrucks,
    carrierRating,
    statusToTMS,
    pickupSenderPIN,
    deliveryReceiverPIN,
  } = order;

  const fetchOrder = async () => {
    try {
      const order = await dispatch(getOrder({ id })).unwrap();
    } catch (error) {
      console.error("Error fetching order:", error);
      toast.show({
        description: "Failed to update the order. Please try again.",
        ...toastErrorConfig,
      });
    }
  };

  const handleSendInfo = (type) => {
    setSendInfoModalOpen(true);
    setInfoType(type); // 'pickup' or 'delivery'
  };

  const closeSendInfoModal = () => setSendInfoModalOpen(false);

  const handleSendSms = (phone, infoType) => {
    const text = `Order details: https://skipyourbroker.com/order/${id}.
    Delivery company: ${carrierName}
        Phone: ${carrierPhone}
        Email: ${carrierEmail}
        ${
          infoType === "pickup"
            ? `Sender PIN: ${order.pickupSenderPIN}`
            : `Recipient PIN: ${order.deliveryReceiverPIN}`
        }
    `;
    dispatch(sendSMS({ phoneNumber: phone, text }));
    setSendInfoModalOpen(false);
    toast.show({
      description: `${infoType} info sent via SMS.`,
      ...toastSuccessConfig,
    });
  };

  const handleSendEmail = (email, infoType) => {
    const emailData = {
      email,
      subject: `Order details - ${id}`,
      html: `Delivery company: ${carrierName}<br>
        Phone: ${carrierPhone}<br>
        Email: ${carrierEmail}<br>
        ${
          infoType === "pickup"
            ? `Sender PIN: ${order.pickupSenderPIN}<br>`
            : `Recipient PIN: ${order.deliveryReceiverPIN}<br>`
        }</br>`,
    };
    dispatch(sendEmail(emailData));
    setSendInfoModalOpen(false);
    toast.show({
      description: `${infoType} info sent via Email.`,
      ...toastSuccessConfig,
    });
  };

  const nextModalImage = () => {
    setModalImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevModalImage = () => {
    setModalImageIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const sendOrder = (id) => {
    const today = new Date().toISOString().split("T")[0];
    // Check if the pickup date is before today
    if (pickUpDate1 < today) {
      toast.show({
        description: `Pickup date cannot be in the past. Please edit the order and select a valid date`,
        ...toastErrorConfig,
      });
      return;
    }
    setIsLoading(true);
    dispatch(updateOrder({ id, statusToTMS: "sending" }));
    dispatch(sendOrderToTMS({ data: order }))
      .unwrap()
      .then((sentOrder) => {
        if (sentOrder.success) {
          dispatch(updateOrder({ id, statusToTMS: "sent", status: "auction" }));
          toast.show({
            description: `Order was successfully sent`,
            ...toastSuccessConfig,
          });
        } else {
          dispatch(updateOrder({ id: order.id, statusToTMS: "notsent" }));
          toast.show({
            description: sentOrder.message || `Order couldn't be processed`,
            ...toastErrorConfig,
          });
        }
      })
      .catch((err) => {
        dispatch(updateOrder({ id: order.id, statusToTMS: "notsent" }));
        toast.show({
          description: `Something went wrong on our side, your order wasn't sent`,
          ...toastErrorConfig,
        });
        console.log("err", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const editOrder = (id) => {
    navigate(`/edit/1/${id}`);
  };

  const cancelOrder = (id) => {
    setIsLoading(true);
    dispatch(performCancelOrder({ id }))
      .unwrap()
      .then(() => {
        dispatch(updateOrder({ id, statusToTMS: "draft", status: "new" }));
        toast.show({
          description: "Order has been canceled successfully.",
          ...toastSuccessConfig,
        });
        navigate("/orders");
      })
      .catch((err) => {
        toast.show({
          description: "Failed to cancel the order.",
          ...toastErrorConfig,
        });
        console.log("Error canceling order:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCancelClick = () => {
    if (isFeeApplied(order.pickUpDate1)) {
      console.log("Fee should be applied");
      setSelectReasonModalOpen(true);
    } else {
      console.log("No fee needed");
      setCancelWithoutFeeAlertOpen(true);
    }
  };

  const handleReasonSelect = (reason) => {
    if (!reason) {
      toast.show({
        description: "Please select the reason",
        ...toastErrorConfig,
      });
      return;
    }
    setSelectReasonModalOpen(false);
    if (reason === "Change of plans") {
      setCancelWithFeeAlertOpen(true);
    } else if (reason === "Carrier didn't arrive") {
      handleCarrierNotArrived();
    }
  };

  const handleCarrierNotArrived = () => {
    try {
      dispatch(setCarrierProblemStatus(order.id));
      dispatch(updateOrder({ id: order.id, status: "carrierProblem" }));
      toast.show({
        description: "Support agent will take care if it soon",
        ...toastSuccessConfig,
      });
    } catch (error) {
      console.error("Error setting carrier problem status:", error);
      toast.show({
        description: "Failed to update order status. Please try again.",
        ...toastErrorConfig,
      });
    }
  };

  const handleCancelWithFee = () => {
    setCancelWithFeeAlertOpen(false);
    dispatch(updateOrder({ id: order.id, isCancelling: true }));
    dispatch(cancelOrderWithFee(order.id))
      .unwrap()
      .then(() => {
        dispatch(updateOrder({ id: order.id, isCancelling: false }));
        toast.show({
          description: "Order canceled with a $200 cancellation fee.",
          ...toastSuccessConfig,
        });
        navigate("/orders");
      })
      .catch(() => {
        dispatch(updateOrder({ id: order.id, isCancelling: false }));
        toast.show({
          description: "We couldn't cancel your order",
          ...toastErrorConfig,
        });
      });
  };

  const handleCancelWithoutFee = () => {
    setCancelWithoutFeeAlertOpen(false);
    dispatch(updateOrder({ id: order.id, isCancelling: true }));
    dispatch(cancelOrderWithoutFee(order.id))
      .unwrap()
      .then(() => {
        dispatch(updateOrder({ id: order.id, isCancelling: false }));
        toast.show({
          description: "Order canceled with a full refund.",
          ...toastSuccessConfig,
        });
        navigate("/orders");
      })
      .catch(() => {
        dispatch(updateOrder({ id: order.id, isCancelling: false }));
        toast.show({
          description: "We couldn't cancel your order",
          ...toastErrorConfig,
        });
      });
  };

  const CancelOrderFlow = () => {
    const [reason, setReason] = useState("");
    return (
      <>
        <Modal
          leastDestructiveRef={cancelRef}
          isOpen={selectReasonModalOpen}
          onClose={() => setSelectReasonModalOpen(false)}
          useRNModal={true}
        >
          <Modal.Content>
            <Modal.Header>{`Cancel Order for ${vehicles[0].year} ${vehicles[0].brand} ${vehicles[0].model}`}</Modal.Header>
            <Modal.Body>
              <FormControl>
                <Text>Please select the reason for cancellation</Text>
                <FormControl.Label>Reason</FormControl.Label>
                <Select
                  placeholder="Select reason"
                  accessibilityLabel="reason"
                  selectedValue={reason}
                  onValueChange={(reason) => setReason(reason)}
                >
                  <Select.Item
                    label="Change of plans"
                    value="Change of plans"
                  />
                  <Select.Item
                    label="Carrier didn't arrive"
                    value="Carrier didn't arrive"
                  />
                </Select>
              </FormControl>
            </Modal.Body>
            <Modal.Footer>
              <Button
                disabled={!reason}
                onPress={() => {
                  handleReasonSelect(reason);
                }}
              >
                Confirm
              </Button>
              <Button
                style={{ marginTop: 10 }}
                variant="cancel"
                onPress={() => setSelectReasonModalOpen(false)}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Modal.Content>
        </Modal>

        <AlertDialog
          leastDestructiveRef={cancelRef}
          isOpen={cancelWithoutFeeAlertOpen}
          onClose={() => setCancelWithoutFeeAlertOpen(false)}
          useRNModal={true}
        >
          <AlertDialog.Content>
            <AlertDialog.Header>
              {`Are you sure you want to cancel the order with ${vehicles[0].year} ${vehicles[0].brand} ${vehicles[0].model}?`}
            </AlertDialog.Header>
            <AlertDialog.Body>
              <Text>
                Your order will be saved as draft and carrier won't be able to
                complete it for you. You will receive a full refund to your
                card.
              </Text>
            </AlertDialog.Body>
            <AlertDialog.Footer>
              <Button onPress={handleCancelWithoutFee}>Confirm</Button>
              <Button
                style={{ marginTop: 10 }}
                variant="cancel"
                onPress={() => setCancelWithoutFeeAlertOpen(false)}
              >
                Cancel
              </Button>
            </AlertDialog.Footer>
          </AlertDialog.Content>
        </AlertDialog>

        <AlertDialog
          leastDestructiveRef={cancelRef}
          isOpen={cancelWithFeeAlertOpen}
          onClose={() => setCancelWithFeeAlertOpen(false)}
          useRNModal={true}
        >
          <AlertDialog.Content>
            <AlertDialog.Header>Final Confirmation</AlertDialog.Header>
            <AlertDialog.Body>
              <Text>
                Your order will be canceled and saved as a draft and you will be
                charged $200 cancellation fee. The rest of your payment will be
                refunded to you card.
              </Text>
            </AlertDialog.Body>
            <AlertDialog.Footer>
              <Button onPress={handleCancelWithFee}>Confirm</Button>
              <Button
                style={{ marginTop: 10 }}
                variant="cancel"
                onPress={() => setCancelWithFeeAlertOpen(false)}
              >
                Cancel
              </Button>
            </AlertDialog.Footer>
          </AlertDialog.Content>
        </AlertDialog>
      </>
    );
  };

  const {
    vin,
    brand,
    model,
    year,
    length,
    height,
    width,
    additionalEquipment,
    customChanges,
    personalItems,
    images,
    isInoperable,
    isTrailerEnclosed,
    weight,
  } = order.vehicles[0];

  const [mainImage, setMainImage] = useState(images?.length ? images[0] : null);
  const [isModalOpen, setModalOpen] = useState(false);
  const openModal = (index) => {
    setModalImageIndex(index); // Open the modal with the selected image
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };
  const onClose = () => {
    setShownDeleteModal(false);
  };
  const onDelete = () => {
    try {
      dispatch(deleteOrder({ id })).unwrap();
      setShownDeleteModal(false);
      toast.show({
        description: "Order was successfully deleted",
        ...toastSuccessConfig,
      });
      navigate("/");
    } catch (error) {
      toast.show({
        description: "Failed to delete order",
        ...toastErrorConfig,
      });
    }
  };

  const CompleteAlert = () => {
    const onClose = () => {
      setCompleteModalIsOpen(false);
    };
    const complete = () => {
      setCompleteModalIsOpen(false);
      dispatch(
        completeOrder({
          data: {
            ...order,
          },
        })
      )
        .unwrap()
        .then(() => {
          dispatch(updateOrder({ id, status: "completed" }));
          toast.show({
            description: "Order marked as completed successfully.",
            ...toastSuccessConfig,
          });
          setRateModalIsOpen(true);
        })
        .catch((error) => {
          toast.show({
            description: "Failed to complete order. Please try again.",
            ...toastErrorConfig,
          });
          console.error("Error completing order:", error);
        });
    };
    return (
      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={completeModalIsOpen}
        onClose={onClose}
        useRNModal={true}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Complete Order</AlertDialog.Header>
          <AlertDialog.Body>
            Are you sure you want to mark the order as completed? Dispute
            options will no longer be available.
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <View style={styles.buttonsRow}>
              <Button
                style={styles.cancel}
                variant="subtle"
                colorScheme="coolGray"
                onPress={onClose}
                ref={cancelRef}
              >
                Cancel
              </Button>
              <Button style={styles.acButton} onPress={complete}>
                Complete
              </Button>
            </View>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  const DisputeAlert = () => {
    const [disputeText, setDisputeText] = useState("");
    const [assets, setAssets] = useState([]);
    const [images, setImages] = useState([]);

    const onClose = () => {
      setDisputeModalIsOpen(false);
    };

    const sendDispute = () => {
      setDisputeModalIsOpen(false);
      dispatch(
        disputeOrder({
          data: {
            id: order.id,
            disputeText,
            disputeImages: images,
          },
        })
      )
        .unwrap()
        .then(() => {
          dispatch(updateOrder({ id, status: "dispute" }));
          toast.show({
            description: "Order was successfully disputed.",
            ...toastSuccessConfig,
          });
        })
        .catch((error) => {
          toast.show({
            description: "Failed to dispute order.",
            ...toastErrorConfig,
          });
          console.error("Error disputing order:", error);
        });
    };

    const uploadResource = async ({ file }) => {
      try {
        const formattedFileName = file.name.replace(/\s+/g, "-"); // Format the file name

        const formData = new FormData();
        formData.append("file", file, formattedFileName);

        const result = await instance.post(
          `${API_URL}/orders/upload`,
          formData
        );

        if (result?.data?.fileName) {
          // Set the uploaded image URL in the state
          setImages((images) => [...images, result.data.fileName]);
        }
      } catch (error) {
        toast.show({
          description: "Failed to upload image. Please try again.",
          ...toastErrorConfig,
        });
        console.error("Error uploading image: ", error);
      }
    };

    const onChange = (imageList, addUpdateIndex) => {
      if (addUpdateIndex) {
        uploadResource(imageList[addUpdateIndex[0]]);
      }
      setAssets(imageList);
    };

    return (
      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={disputeModalIsOpen}
        onClose={onClose}
        useRNModal={true}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Dispute Request</AlertDialog.Header>
          <AlertDialog.Body>
            <FormControl.Label>Dispute Reason</FormControl.Label>
            <TextArea
              onChangeText={setDisputeText}
              h={20}
              placeholder="Describe the reason for your dispute"
            />
            <ReactImageUploading
              multiple
              value={assets}
              onChange={onChange}
              maxNumber={10}
              dataURLKey="data_url"
            >
              {({ imageList, onImageUpload, onImageRemove, dragProps }) => (
                <div className="upload__image-wrapper">
                  <Pressable
                    onPress={onImageUpload}
                    style={styles.addPhotoContainer}
                    {...dragProps}
                  >
                    <AddImage />
                    <Text style={styles.addPhotoText}>Add Image</Text>
                  </Pressable>
                  <View style={{ flexDirection: "row" }}>
                    {imageList.map((image, index) => (
                      <div
                        key={index}
                        style={{ marginTop: 10, marginRight: 10 }}
                      >
                        <img src={image["data_url"]} alt="" width="100" />
                        <button onClick={() => onImageRemove(index)}>
                          Remove
                        </button>
                      </div>
                    ))}
                  </View>
                </div>
              )}
            </ReactImageUploading>
            <View style={styles.buttonsContainer}></View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                gap: 10,
                marginTop: 15,
              }}
            >
              {assets.map((image, index) => (
                <Pressable onPress={() => {}} key={index}>
                  <Image
                    alt={image.uri}
                    style={styles.image}
                    height={66}
                    width={66}
                    src={image.uri}
                  />
                </Pressable>
              ))}
            </View>
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <View style={styles.buttonsRow}>
              <Button
                style={styles.cancel}
                variant="subtle"
                colorScheme="coolGray"
                onPress={onClose}
                ref={cancelRef}
              >
                Cancel
              </Button>
              <Button style={styles.acButton} onPress={sendDispute}>
                Send Dispute
              </Button>
            </View>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  const RateAlert = () => {
    const [rating, setRating] = useState(0);
    const onClose = () => {
      setRateModalIsOpen(false);
    };

    const sendRate = () => {
      setRateModalIsOpen(false);
      dispatch(
        rateOrder({
          data: {
            id: order.id,
            rating,
            carrierID: order.carrierID,
          },
        })
      )
        .unwrap()
        .then(() => {
          dispatch(updateOrder({ id, status: "completedAndRated" }));
          toast.show({
            description: "Thank you for your feedback!",
            ...toastSuccessConfig,
          });
        })
        .catch((err) => {
          console.error("Error rating order:", err);
          toast.show({
            description: "Failed to submit rating. Please try again.",
            ...toastErrorConfig,
          });
        });
    };

    return (
      <AlertDialog
        leastDestructiveRef={rateRef}
        isOpen={rateModalIsOpen}
        onClose={onClose}
        useRNModal={true}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Rate the Service</AlertDialog.Header>
          <AlertDialog.Body>
            We'd welcome your feedback! Please rate the carrier and the service.
            <Center>
              <StarRating mt={20} rating={rating} onChange={setRating} />
            </Center>
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <View style={styles.buttonsRow}>
              <Button
                style={styles.cancel}
                variant="subtle"
                colorScheme="coolGray"
                onPress={onClose}
                ref={rateRef}
              >
                Cancel
              </Button>
              <Button style={styles.acButton} onPress={sendRate}>
                Submit Rating
              </Button>
            </View>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  const SendInfoModal = ({
    isOpen,
    onClose,
    infoType,
    onSendSms,
    onSendEmail,
  }) => {
    const email =
      infoType === "pickup" ? senderContactEmail : recipientContactEmail;
    const phone =
      infoType === "pickup" ? senderContactPhone : recipientContactPhone;
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>
            <Text>
              {`Send ${
                infoType === "pickup" ? "Pickup" : "Delivery"
              } Informatiion`}
            </Text>
          </Modal.Header>
          <Modal.Body>
            <Text>
              {`Do you want to send the ${
                infoType === "pickup" ? "pickup" : "delivery"
              } information via SMS or Email?`}
            </Text>
            <Text>{`Email: ${email}`}</Text>
            <Text>{`Phone: ${phone}`}</Text>
          </Modal.Body>
          <Modal.Footer>
            <HStack space={3} justifyContent="center" width="100%">
              <Button flex={1} onPress={() => onSendSms(phone, infoType)}>
                Send SMS
              </Button>
              <Button flex={1} onPress={() => onSendEmail(email, infoType)}>
                Send Email
              </Button>
            </HStack>
            <Button
              style={{ marginTop: 10 }}
              variant="cancel"
              onPress={() => setSendInfoModalOpen(false)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    );
  };

  useEffect(() => {
    Image.getSize(imageHost + mainImage, (width, height) => {
      setImageSize({
        width: Math.min(width, 600), // Max width of 800px
        height: Math.min(height, 600), // Max height of 800px
      });
    });
  }, [mainImage]);

  const DeleteAlert = () => {
    return (
      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={shownDeleteModal}
        onClose={onClose}
        useRNModal={true}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Delete Order</AlertDialog.Header>
          <AlertDialog.Body>
            Are you sure you want to delete the order?
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <Button.Group space={2}>
              <Button
                style={styles.cancel}
                variant="cancel"
                onPress={onClose}
                ref={cancelRef}
              >
                Cancel
              </Button>
              <Button
                variant="fatal"
                style={styles.dcButton}
                onPress={onDelete}
              >
                Delete
              </Button>
            </Button.Group>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  const LocationInfo = () => {
    const [isPickupPinVisible, setPickupPinVisible] = useState(false);
    const [isDeliveryPinVisible, setDeliveryPinVisible] = useState(false);

    // Toggle functions for each PIN visibility
    const togglePickupPinVisibility = () =>
      setPickupPinVisible(!isPickupPinVisible);
    const toggleDeliveryPinVisibility = () =>
      setDeliveryPinVisible(!isDeliveryPinVisible);

    return (
      <>
        <HStack style={styles.locationPinWrapper}>
          <View style={styles.locationDetailsWrapper}>
            <LocationGreen />
            <View style={styles.locationTextWrapper}>
              <Text style={styles.locationText}>Pick Up Location</Text>
              <View style={styles.dateRow}>
                <View style={styles.addressIcon}>
                  <AddressGrey />
                </View>
                <Text>{pickUpLocation}</Text>
              </View>
              <View style={styles.dateRow}>
                <View style={styles.calendarIcon}>
                  <CalendarGrey />
                </View>
                <Text>{formatStringToDate(pickUpDate1)}</Text>
              </View>
            </View>
          </View>
          {/* PIN and Buttons Section */}
          {(status === "waitingPickUp" || status === "pickedUp") && (
            <View style={styles.pinContainer}>
              <HStack alignItems="center">
                <View style={(styles.sendButton, { marginRight: 16 })}>
                  <Button
                    isDisabled={!active}
                    onPress={() => handleSendInfo("pickup")}
                  >
                    Send Info
                  </Button>
                </View>
                <View>
                  <Text style={styles.headerBlock}>Pick Up PIN</Text>
                  <HStack
                    style={{ justifyContent: "space-between" }}
                    alignItems="center"
                  >
                    <Text style={styles.pinText}>
                      {isPickupPinVisible ? pickupSenderPIN : "****"}
                    </Text>
                    <Pressable onPress={togglePickupPinVisibility}>
                      <View style={styles.iconWrapper}>
                        {isPickupPinVisible ? <EyeSlash /> : <Eye />}
                      </View>
                    </Pressable>
                  </HStack>
                </View>
              </HStack>
            </View>
          )}
        </HStack>

        <HStack style={styles.locationPinWrapper}>
          <View style={styles.locationDetailsWrapper}>
            <GeoGreen />
            <View style={styles.locationTextWrapper}>
              <Text style={styles.locationText}>Delivery Location</Text>
              <View style={styles.dateRow}>
                <View style={styles.addressIcon}>
                  <AddressGrey />
                </View>
                <Text>{deliveryLocation}</Text>
              </View>
              <View style={styles.dateRow}>
                <View style={styles.calendarIcon}>
                  <CalendarGrey />
                </View>
                <Text>{formatStringToDate(deliveryDate1)}</Text>
              </View>
            </View>
          </View>
          {(status === "waitingPickUp" || status === "pickedUp") && (
            <View style={styles.pinContainer}>
              <HStack alignItems="center">
                <View style={styles.sendButton}>
                  <Button
                    isDisabled={!active}
                    onPress={() => handleSendInfo("delivery")}
                  >
                    Send Info
                  </Button>
                </View>
                <View>
                  <Text style={styles.headerBlock}>Delivery PIN</Text>
                  <HStack
                    style={{ justifyContent: "space-between" }}
                    alignItems="center"
                  >
                    <Text style={styles.pinText}>
                      {isDeliveryPinVisible ? deliveryReceiverPIN : "****"}
                    </Text>
                    <Pressable onPress={toggleDeliveryPinVisibility}>
                      <View style={styles.iconWrapper}>
                        {isDeliveryPinVisible ? <EyeSlash /> : <Eye />}
                      </View>
                    </Pressable>
                  </HStack>
                </View>
              </HStack>
            </View>
          )}
        </HStack>
      </>
    );
  };

  useEffect(() => {
    fetchOrder();
  }, []);

  return (
    <>
      <ScrollView style={styles.container}>
        <View style={styles.status}>
          <Text>Status: </Text>
          <BidStatus
            status={order.statusToTMS === "notsent" ? "notSent" : status}
          />
        </View>
        {!!images?.length && (
          <Pressable
            onPress={() => {
              const selectedIndex = images.findIndex(
                (img) => img === mainImage
              );
              openModal(selectedIndex);
            }}
          >
            <Image
              style={styles.image}
              height={200}
              src={imageHost + mainImage}
              alt={`Main Image ${mainImageIndex + 1}`}
            />
          </Pressable>
        )}
        <ScrollView horizontal style={{ marginVertical: 10 }}>
          {images.map((image, index) => (
            <Pressable onPress={() => setMainImage(image)} key={index}>
              <Image
                style={[
                  styles.imageSmall,
                  mainImage === image && styles.activeImage,
                ]}
                src={imageHost + image}
                alt={`Thumbnail ${index + 1}`}
              />
            </Pressable>
          ))}
        </ScrollView>
        <Text style={styles.header}>
          {year} {brand} {model}
        </Text>
        <View style={styles.badges}>
          <View mr={"4px"} ml={"4px"} style={styles.badge}>
            <Weight />
            <Text style={styles.badgeText}>{weight}</Text>
          </View>
          <View mr={"4px"} style={styles.badge}>
            <Length />
            <Text style={styles.badgeText}>{length}</Text>
          </View>
          <View mr={"4px"} ml={"4px"} style={styles.badge}>
            <Height />
            <Text style={styles.badgeText}>{height}</Text>
          </View>
          <View ml={"4px"} style={styles.badge}>
            <Width />
            <Text style={styles.badgeText}>{width}</Text>
          </View>
        </View>
        <View shadow="3" style={styles.vinBadge}>
          <VinGreen />
          <View style={styles.vinText}>
            <Text style={styles.greyText}>VIN</Text>
            <Text>{vin}</Text>
          </View>
        </View>
        {!!additionalEquipment?.length && (
          <>
            <Text style={styles.headerBlock}>Additional equipment</Text>
            <Text>{additionalEquipment}</Text>
          </>
        )}
        <>
          <Text style={styles.headerBlock}>Is car inoperable?</Text>
          <Text>{isInoperable ? "Yes" : "No"}</Text>
        </>
        <>
          <Text style={styles.headerBlock}>Trailer Type</Text>
          {/* {trailerType === "closed" && (
          <View style={styles.row}>
            <TrailerClosed />
            <Text style={styles.ml4} fontSize="sm">
              Enclosed Trailer
            </Text>
          </View>
        )}
        {trailerType === "open" && (
          <View style={styles.row}>
            <TrailerOpen />
            <Text style={styles.ml4} fontSize="sm">
              Open Trailer
            </Text>
          </View>
        )} */}
          {isTrailerEnclosed ? (
            <View style={styles.row}>
              <TrailerClosed />
              <Text style={styles.ml4} fontSize="sm">
                Enclosed Trailer
              </Text>
            </View>
          ) : (
            <View style={styles.row}>
              <TrailerOpen />
              <Text style={styles.ml4} fontSize="sm">
                Open Trailer
              </Text>
            </View>
          )}
        </>
        {!!customChanges?.length && (
          <>
            <Text style={styles.headerBlock}>
              Car has custom changes/tuning
            </Text>
            <Text>{customChanges}</Text>
          </>
        )}
        {!!personalItems?.length && (
          <>
            <Text style={styles.headerBlock}>Personal items</Text>
            <Text>{personalItems}</Text>
          </>
        )}

        <LocationInfo />
        {/* <HStack style={styles.locationPinWrapper}>
          <View style={styles.locationDetailsWrapper}>
            <LocationGreen />
            <View style={styles.locationTextWrapper}>
              <Text style={styles.locationText}>Pick Up Location</Text>
              <View style={styles.dateRow}>
                <View style={styles.addressIcon}>
                  <AddressGrey />
                </View>
                <Text>{pickUpLocation}</Text>
              </View>
              <View style={styles.dateRow}>
                <View style={styles.calendarIcon}>
                  <CalendarGrey />
                </View>
                <Text>{formatStringToDate(pickUpDate1)}</Text>
              </View>
            </View>
          </View>
          {(status === "waitingPickUp" || status === "pickedUp") && (
            <View style={styles.pinContainer}>
              <Text style={styles.headerBlock}>Pick Up PIN</Text>
              <HStack alignItems="center">
                <Text style={styles.pinText}>
                  {isPickupPinVisible ? pickupSenderPIN : "****"}
                </Text>
                <Pressable onPress={togglePickupPinVisibility}>
                  <View style={styles.iconWrapper}>
                    {isPickupPinVisible ? <EyeSlash /> : <Eye />}
                  </View>
                </Pressable>
              </HStack>
              <HStack style={styles.buttonContainer}>
                <Button onPress={sendPickupSms} title="Send SMS" />
                <Button onPress={sendPickupEmail} title="Send Email" />
              </HStack>
            </View>
          )}
        </HStack>

        <HStack style={styles.locationPinWrapper}>
          <View style={styles.locationDetailsWrapper}>
            <GeoGreen />
            <View style={styles.locationTextWrapper}>
              <Text style={styles.locationText}>Delivery Location</Text>
              <View style={styles.dateRow}>
                <View style={styles.addressIcon}>
                  <AddressGrey />
                </View>
                <Text>{deliveryLocation}</Text>
              </View>
              <View style={styles.dateRow}>
                <View style={styles.calendarIcon}>
                  <CalendarGrey />
                </View>
                <Text>{formatStringToDate(deliveryDate1)}</Text>
              </View>
            </View>
          </View>
          {(status === "waitingPickUp" || status === "pickedUp") && (
            <View style={styles.pinContainer}>
              <Text style={styles.headerBlock}>Delivery PIN</Text>
              <HStack alignItems="center">
                <Text style={styles.pinText}>
                  {isDeliveryPinVisible ? deliveryReceiverPIN : "****"}
                </Text>
                <Pressable onPress={toggleDeliveryPinVisibility}>
                  <View style={styles.iconWrapper}>
                    {isDeliveryPinVisible ? <EyeSlash /> : <Eye />}
                  </View>
                </Pressable>
              </HStack>
            </View>
          )}
        </HStack> */}

        <>
          <Text style={styles.headerBlock}>Sender information</Text>
          <View style={styles.recipientRow}>
            <GreenUser />
            <Text style={styles.locationTextWrapper}>{senderContactName}</Text>
          </View>
          <View style={styles.recipientRow}>
            <GreenEmail />
            <Text style={styles.locationTextWrapper}>{senderContactEmail}</Text>
          </View>
          <View style={styles.recipientRow}>
            <GreenPhone />
            <Text style={styles.locationTextWrapper}>{senderContactPhone}</Text>
          </View>
          <Text style={styles.headerBlock}>Recipient information</Text>
          <View style={styles.recipientRow}>
            <GreenUser />
            <Text style={styles.locationTextWrapper}>
              {recipientContactName}
            </Text>
          </View>
          <View style={styles.recipientRow}>
            <GreenEmail />
            <Text style={styles.locationTextWrapper}>
              {recipientContactEmail}
            </Text>
          </View>
          <View style={styles.recipientRow}>
            <GreenPhone />
            <Text style={styles.locationTextWrapper}>
              {recipientContactPhone}
            </Text>
          </View>
        </>
        {carrierName &&
          carrierEmail &&
          carrierPhone &&
          carrierSince &&
          bidAmount && (
            <View style={styles.carrierDetails}>
              <Text style={styles.headerBlock}>Carrier Information</Text>
              <View style={styles.recipientRow}>
                <GreenUser />
                <Text style={styles.locationTextWrapper}>{carrierName}</Text>
              </View>
              <View style={styles.recipientRow}>
                <GreenEmail />
                <Text style={styles.locationTextWrapper}>{carrierEmail}</Text>
              </View>
              <View style={styles.recipientRow}>
                <GreenPhone />
                <Text style={styles.locationTextWrapper}>{carrierPhone}</Text>
              </View>
              <View style={styles.recipientRow}>
                <GreenPhone />
                <Text style={styles.locationTextWrapper}>
                  Number of trucks: {numberOfTrucks}
                </Text>
              </View>
              <View style={styles.recipientRow}>
                <GreenPhone />
                <Text style={styles.locationTextWrapper}>
                  Rating: {carrierRating}
                </Text>
              </View>
              <View style={styles.recipientRow}>
                <GreenPhone />
                <Text style={styles.locationTextWrapper}>
                  Carrier since: {carrierSince}
                </Text>
              </View>
              <View style={styles.recipientRow}>
                <GreenPhone />
                <Text style={styles.locationTextWrapper}>
                  Delivery cost: {bidAmount}
                </Text>
              </View>
            </View>
          )}
        {status === "auction" && statusToTMS === "sent" && (
          <View style={styles.blockWrapper}>
            <Text style={styles.header}>Bids</Text>
            <Button
              onPress={() => {
                navigate(`/bids/${id}`);
              }}
            >
              View Bids
            </Button>
            {/*<BidsScreen showTitle={false} />*/}
          </View>
        )}
        {(status === "delivered" || status === "dispute") && (
          <View style={styles.blockWrapper}>
            <Text style={styles.header}>Complete Order</Text>

            <Button
              isDisabled={!active}
              onPress={() => {
                setCompleteModalIsOpen(true);
              }}
            >
              <Text style={styles.buttonText}>Complete Order</Text>
            </Button>
          </View>
        )}
        {status === "completed" && (
          <View style={styles.blockWrapper}>
            <Text style={styles.header}>Rate Order</Text>

            <Button
              isDisabled={!active}
              onPress={() => {
                setRateModalIsOpen(true);
              }}
            >
              <Text style={styles.buttonText}>Rate Order</Text>
            </Button>
          </View>
        )}
        {status === "delivered" && (
          <View style={styles.blockWrapper}>
            <Text style={styles.header}>Start dispute</Text>

            <Button
              isDisabled={!active}
              onPress={() => {
                setDisputeModalIsOpen(true);
              }}
              variant="fatal"
            >
              <Text style={styles.buttonText}>Start dispute</Text>
            </Button>
          </View>
        )}
        {status === "auction" && statusToTMS === "sent" && (
          <View style={styles.blockWrapper}>
            <Text style={styles.header}>Cancel Order</Text>
            <Button
              isDisabled={!active}
              variant="warning"
              onPress={() => cancelOrder(id)}
            >
              <Text style={styles.buttonText}>Cancel Order</Text>
            </Button>
          </View>
        )}

        {status === "waitingPickUp" && (
          <View style={styles.blockWrapper}>
            <Text style={styles.header}>Cancel Order</Text>
            <Button
              isDisabled={!active}
              variant="fatal"
              onPress={() => handleCancelClick()}
            >
              <Text style={styles.buttonText}>Cancel Order</Text>
            </Button>
          </View>
        )}
        {status === "new" &&
          (statusToTMS === "draft" || statusToTMS === "notsent") && (
            <View style={styles.blockWrapper}>
              <Text style={styles.header}>Edit Order</Text>
              <Button isDisabled={!active} onPress={() => editOrder(id)}>
                Edit Order
              </Button>
            </View>
          )}

        {status === "new" && (
          <View style={styles.blockWrapper}>
            <Text style={styles.header}>Send Order</Text>
            <Button
              isDisabled={!active}
              variant="warning"
              onPress={() => sendOrder(id)}
            >
              <Text style={styles.buttonText}>Send Order</Text>
            </Button>
          </View>
        )}
        {(status === "auction" || status === "new") && (
          <View style={styles.blockWrapper}>
            <Text style={styles.header}>Delete Order</Text>
            <Button
              isDisabled={!active}
              variant="fatal"
              onPress={() => {
                setShownDeleteModal(true);
              }}
            >
              <Text style={styles.buttonText}>Delete Order</Text>
            </Button>
          </View>
        )}
        <View style={styles.divider} />
      </ScrollView>
      <DeleteAlert />
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        size="lg"
        useRNModal={true}
      >
        <Modal.Content maxWidth="90%" maxHeight="90%">
          <Modal.CloseButton />
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <HStack alignItems="center" space={2}>
              {/* Previous Image Button */}
              <IconButton
                icon={<ArrowBackIcon />}
                onPress={prevModalImage}
                variant="ghost"
                isDisabled={modalImageIndex === 0}
              />

              {/* Display the Current Modal Imsage */}
              <Image
                source={{ uri: imageHost + images[modalImageIndex] }}
                alt={`Image ${modalImageIndex + 1}`}
                style={{
                  width: imageSize.width,
                  height: imageSize.height,
                  borderRadius: 12,
                }}
                resizeMode="contain"
              />

              {/* Next Image Button */}
              <IconButton
                icon={<ArrowForwardIcon />}
                onPress={nextModalImage}
                variant="ghost"
                isDisabled={modalImageIndex === images.length - 1}
              />
            </HStack>
          </View>
        </Modal.Content>
      </Modal>
      <Modal
        isOpen={isLoading}
        onClose={() => {}}
        size="full"
        backdropOpacity={0.7}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner size="lg" color="#0000ff" />
          <Text style={{ color: "#ffffff", marginTop: 10, fontSize: 20 }}>
            Sending your request...
          </Text>
        </View>
      </Modal>
      <Modal
        isOpen={order.isCancelling}
        onClose={() => {}}
        size="full"
        backdropOpacity={0.7}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner size="lg" color="#0000ff" />
          <Text style={{ color: "#ffffff", marginTop: 10, fontSize: 20 }}>
            Your order is being cancelled...
          </Text>
        </View>
      </Modal>
      <CompleteAlert />
      <DisputeAlert />
      <RateAlert />
      <CancelOrderFlow />
      <SendInfoModal
        isOpen={isSendInfoModalOpen}
        onClose={closeSendInfoModal}
        infoType={infoType}
        onSendSms={handleSendSms}
        onSendEmail={handleSendEmail}
      />
    </>
  );
}
