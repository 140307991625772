const styles = {
  container: {
    paddingHorizontal: 20,
    paddingBottom: 20,
  //  paddingTop: 20,
    height: "90vh",
    flex: 1,
  },
  pageName: {
    marginBottom: 20,
   // marginTop: 5,
    fontSize: 18,
    fontWeight: "500",
    textAlign: "center"
  },
  bidWrapper: {
    //borderColor: "#E5E5E5",
   // borderWidth: 1,
  //  padding: 2,
    backgroundColor: "#F3F4F6",
    borderColor: "#1DCE80",
    borderWidth: 2,
    borderRadius: 12,
    padding: 10,
    marginBottom: 12,
  },
  bidName: {
    fontSize: 16,
    fontWeight: "600",
  },
  bidPrice: {
    fontSize: 16,
    fontWeight: "400",
  },
  buttonsWrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  button: {
    flex: 1,
    height: 34,
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    borderRadius: 12,
  },
  buttonText: {
    color: "#fff",
    fontSize: 12,
    fontWeight: "600",
  },
  divider: {
    width: 6,
  },
  counterButton: {
    borderColor: "#000",
    borderWidth: 1,
  },
  counterButtonText: {
    color: "#000",
    fontSize: 12,
    fontWeight: "600",
  },
  
 
  buttonsRow: {
    flexDirection: "row",
    flex: 2,
  },
  cancel: {
    flex: 1,
    borderWidth: 1,
    borderColor: "#000",
    marginRight: 3,
  },
  acButton: {
    flex: 1,
    marginLeft: 3,
  },
  appleButton: {
    flex: 1,
    marginLeft: 3,
    backgroundColor: "#000000",
  },
  dcButton: {
    flex: 1,
    marginLeft: 3,
    backgroundColor: "#B91C1C",
  },
  ratingWrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  starStyle: {
    marginHorizontal: 0,
  },
  leftIcon: {
    marginLeft: 12,
  },
  newBidText: {
    fontSize: 14,
    fontWeight: "700",
    color: "#1DCE80",
    marginBottom: 8,
  },
  inactiveBidWrapper: {
    borderColor: "#E5E7EB",
    borderWidth: 1,
    borderRadius: 8,
    padding: 8,
    marginBottom: 8,
  },
  previousBidsWrapper: {
    marginTop: 8,
    padding: 8,
    backgroundColor: "#F1F5F9",
    borderRadius: 8,
  },
  foldButton: {
    marginVertical: 12,
    alignItems: "center",
  },
  foldButtonText: {
    color: "#1D4ED8",
    fontWeight: "600",
    fontSize: 14,
  },
};

export default styles;
