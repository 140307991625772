import * as React from "react";
import {
  AlertDialog,
  Button,
  Center,
  FlatList,
  FormControl,
  Image,
  Pressable,
  Text,
  TextArea,
  View,
  Box,
  Select,
  Spinner,
  useToast,
  ScrollView,
  Modal,
} from "native-base";
import StarRating from "react-native-star-rating-widget";

import styles from "./styles";

import {
  AddImage,
  Calendar,
  EmptyOrdersImage,
  Line,
  Location,
  Plus,
  Truck2,
  Upload,
} from "../../assets/images/svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfile,
  profileAddFCM,
  getOrder,
} from "../../networking/profile/profileApi";
import { formatStringToDate } from "../../utils";
import { useState, useEffect, useRef } from "react";
import {
  completeOrder,
  disputeOrder,
  rateOrder,
  sendOrderToTMS,
} from "../../networking/orders/ordersApi";
import { useNavigate } from "react-router-dom";
import isFeeApplied from "../../utils";
import BidStatus from "../../components/BidStatus";
import { clearInputValues } from "../../networking/orders/ordersSlice";
import ReactImageUploading from "react-images-uploading";
import instance from "../../networking/axios";
import { API_URL } from "../../config";
import { updateOrder } from "../../networking/profile/profileSlice";
import { toastErrorConfig, toastSuccessConfig } from "../../config/constants";
import {
  cancelOrderWithFee,
  cancelOrderWithoutFee,
  setCarrierProblemStatus,
} from "../../networking/orders/ordersApi";
import { globalLogout } from "../../networking/globalActions";

export default function OrdersListScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const [refreshing, setRefreshing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [completeModalIsOpen, setCompleteModalIsOpen] = useState(false);
  const [disputeModalIsOpen, setDisputeModalIsOpen] = useState(false);
  const [rateModalIsOpen, setRateModalIsOpen] = useState(false);
  const completeRef = React.useRef(null);
  const disputeRef = React.useRef(null);
  const rateRef = React.useRef(null);
  const [currentOrder, setCurrentOrder] = useState({});
  const { orders, active } = useSelector((state) => state.profile);
  const [filterStatus, setFilterStatus] = useState("all"); // Filter state

  useEffect(() => {
    fetchData();
    dispatch(clearInputValues());
  }, [dispatch]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const user = await dispatch(getProfile({})).unwrap();
      // logout in case user has deleted: true
      if (user.deleted) {
        dispatch(globalLogout());
        return;
      }
    } catch (error) {
      console.log("Error fetching profile:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRefresh = async () => {
    try {
      setRefreshing(true);
      await dispatch(getProfile({})).unwrap();
    } catch (error) {
      console.log("Error refreshing orders:", error);
    } finally {
      setRefreshing(false);
    }
  };

  const createNewOrder = () => {
    navigate("/create/1");
  };

  const sendOrder = (order) => {
    const today = new Date().toISOString().split("T")[0];
    // Check if the pickup date is before today
    if (order.pickUpDate1 < today) {
      toast.show({
        description: `Pickup date cannot be in the past. Please edit the order and select a valid date`,
        ...toastErrorConfig,
      });
      return;
    }
    const id = order.id;
    dispatch(updateOrder({ id, statusToTMS: "sending" }));
    dispatch(sendOrderToTMS({ data: order }))
      .unwrap()
      .then((sentOrder) => {
        if (sentOrder.success) {
          dispatch(updateOrder({ id, statusToTMS: "sent", status: "auction" }));
          toast.show({
            description: `Order was successfully sent`,
            ...toastSuccessConfig,
          });
        } else {
          dispatch(updateOrder({ id: order.id, statusToTMS: "notsent" }));
          toast.show({
            description: sentOrder.message || `Order couldn't be processed`,
            ...toastErrorConfig,
          });
        }
      })
      .catch((err) => {
        dispatch(updateOrder({ id: order.id, statusToTMS: "notsent" }));
        toast.show({
          description: `Something went wrong on our side, your order wasn't sent`,
          ...toastErrorConfig,
        });
        console.log("err", err);
      });
  };

  // Parsing the location into city, state, and zip code
  const parseLocation = (address) => {
    const parts = address?.split(", ");
    return {
      city: parts[1] || "",
      state: parts[2]?.split(" ")[0] || "",
      zipCode: parts[2]?.split(" ")[1] || "",
    };
  };

  const OrderItem = ({ order, vehicle }) => {
    const isInactive = order.statusToTMS === "sending";
    const [step, setStep] = useState(1);
    const [selectReasonModalOpen, setSelectReasonModalOpen] = useState(false);
    const [cancelWithoutFeeAlertOpen, setCancelWithoutFeeAlertOpen] =
      useState(false);
    const [cancelWithFeeAlertOpen, setCancelWithFeeAlert] = useState(false);
    const pickupInfo = parseLocation(order.pickUpLocation);
    const deliveryInfo = parseLocation(order.deliveryLocation);
    const cancelRef = useRef(null);

    const handleCancelClick = () => {
      if (isFeeApplied(order.pickUpDate1)) {
        console.log("Fee should be applied");
        setSelectReasonModalOpen(true);
      } else {
        console.log("No fee needed");
        setCancelWithoutFeeAlertOpen(true);
      }
    };

    const handleReasonSelect = (reason) => {
      if (!reason) {
        toast.show({
          description: "Please select the reason",
          ...toastErrorConfig,
        });
        return;
      }
      setSelectReasonModalOpen(false);
      if (reason === "Change of plans") {
        setCancelWithFeeAlert(true);
      } else if (reason === "Carrier didn't arrive") {
        handleCarrierNotArrived();
      }
    };

    const handleCancelWithFee = () => {
      setCancelWithFeeAlert(false);
      dispatch(updateOrder({ id: order.id, isCancelling: true }));
      dispatch(cancelOrderWithFee(order.id))
        .unwrap()
        .then(() => {
          dispatch(updateOrder({ id: order.id, isCancelling: false }));
          setCancelWithFeeAlert(false);
          toast.show({
            description: "Order canceled with a $200 cancellation fee.",
            ...toastSuccessConfig,
          });
          fetchData();
        })
        .catch((error) => {
          dispatch(updateOrder({ id: order.id, isCancelling: false }));
          toast.show({
            description: "We couldn't cancel your order",
            ...toastErrorConfig,
          });
        });
    };

    const handleCancelWithoutFee = () => {
      setCancelWithoutFeeAlertOpen(false);
      dispatch(updateOrder({ id: order.id, isCancelling: true }));
      dispatch(cancelOrderWithoutFee(order.id))
        .unwrap()
        .then(() => {
          dispatch(updateOrder({ id: order.id, isCancelling: false }));
          setCancelWithoutFeeAlertOpen(false);
          toast.show({
            description: "Order canceled with a full refund.",
            ...toastSuccessConfig,
          });
          fetchData();
        })
        .catch((error) => {
          dispatch(updateOrder({ id: order.id, isCancelling: false }));
          toast.show({
            description: "We couldn't cancel your order",
            ...toastErrorConfig,
          });
        });
    };

    const handleCarrierNotArrived = () => {
      try {
        dispatch(setCarrierProblemStatus(order.id));
        dispatch(updateOrder({ id: order.id, status: "carrierProblem" }));
        toast.show({
          description: "Support agent will take care if it soon",
          ...toastSuccessConfig,
        });
      } catch (error) {
        console.error("Error setting carrier problem status:", error);
        toast.show({
          description: "Failed to update order status. Please try again.",
          ...toastErrorConfig,
        });
      }
    };

    const SelectReasonModal = () => {
      const [reason, setReason] = useState("");
      return (
        <Modal
          leastDestructiveRef={cancelRef}
          isOpen={selectReasonModalOpen}
          onClose={() => setSelectReasonModalOpen(false)}
          useRNModal={true}
        >
          <Modal.Content>
            <Modal.Header>
              {`Are you sure you want to cancel the order with ${vehicle.year} ${vehicle.brand} ${vehicle.model}?`}
            </Modal.Header>
            <Modal.Body>
              <FormControl>
                <Text>Please select the reason for cancellation</Text>
                <FormControl.Label>Reason</FormControl.Label>
                <Select
                  placeholder="Select reason"
                  accessibilityLabel="reason"
                  selectedValue={reason}
                  onValueChange={(reason) => setReason(reason)}
                >
                  <Select.Item
                    label="Change of plans"
                    value="Change of plans"
                  />
                  <Select.Item
                    label="Carrier didn't arrive"
                    value="Carrier didn't arrive"
                  />
                </Select>
              </FormControl>
            </Modal.Body>
            <Modal.Footer>
              <Button
                disabled={!reason}
                onPress={() => {
                  handleReasonSelect(reason);
                }}
              >
                Confirm
              </Button>
              <Button
                style={{ marginTop: 10 }}
                variant="cancel"
                onPress={() => setSelectReasonModalOpen(false)}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      );
    };

    const CancelWithoutFeeAlert = () => {
      return (
        <AlertDialog
          leastDestructiveRef={cancelRef}
          isOpen={cancelWithoutFeeAlertOpen}
          onClose={() => setCancelWithoutFeeAlertOpen(false)}
          useRNModal={true}
        >
          <AlertDialog.Content>
            <AlertDialog.Header>
              {`Are you sure you want to cancel the order with ${vehicle.year} ${vehicle.brand} ${vehicle.model}?`}
            </AlertDialog.Header>
            <AlertDialog.Body>
              <Text>
                Your order will be saved as draft and carrier won't be able to
                complete it for you. You will receive a full refund to your
                card.
              </Text>
            </AlertDialog.Body>
            <AlertDialog.Footer>
              <Button onPress={handleCancelWithoutFee}>Confirm</Button>
              <Button
                style={{ marginTop: 10 }}
                variant="cancel"
                onPress={() => setCancelWithoutFeeAlertOpen(false)}
              >
                Cancel
              </Button>
            </AlertDialog.Footer>
          </AlertDialog.Content>
        </AlertDialog>
      );
    };

    const CancelWithFeeAlert = () => {
      return (
        <AlertDialog
          leastDestructiveRef={cancelRef}
          isOpen={cancelWithFeeAlertOpen}
          onClose={() => setCancelWithFeeAlert(false)}
          useRNModal={true}
        >
          <AlertDialog.Content>
            <AlertDialog.Header>Final Confirmation</AlertDialog.Header>
            <AlertDialog.Body>
              <Text>
                Your order will be canceled and saved as a draft and you will be
                charged $200 cancellation fee. The rest of your payment will be
                refunded to you card.
              </Text>
            </AlertDialog.Body>
            <AlertDialog.Footer>
              <Button onPress={handleCancelWithFee}>Confirm</Button>
              <Button
                style={{ marginTop: 10 }}
                variant="cancel"
                onPress={() => setCancelWithFeeAlert(false)}
              >
                Cancel
              </Button>
            </AlertDialog.Footer>
          </AlertDialog.Content>
        </AlertDialog>
      );
    };

    return (
      <>
        <View style={{ marginHorizontal: 20 }}>
          <Pressable
            onPress={() =>
              !isInactive &&
              !order.isCancelling &&
              navigate(`/order/${order.id}`)
            }
          >
            <View
              style={[
                styles.orderContainer,
                (isInactive || order.isCancelling) && styles.inactiveContainer,
              ]}
            >
              <View style={styles.truckImage}>
                <Truck2 />
              </View>

              <View style={styles.orderInfo}>
                <Text style={styles.orderName}>
                  {vehicle.year} {vehicle.brand} {vehicle.model}
                </Text>
                <View style={styles.auctionStatus}>
                  <BidStatus
                    status={
                      order.statusToTMS === "notsent" ? "notSent" : order.status
                    }
                  />
                </View>
                <View>
                  {/* <Text>Pickup:</Text> */}
                  <View style={styles.orderTextContainer}>
                    <Calendar style={styles.icon} />
                    <Text
                      style={styles.orderText}
                      numberOfLines={1}
                      ellipsizeMode="tail"
                    >
                      Pickup: {formatStringToDate(order.pickUpDate1)}
                    </Text>
                    <Line style={styles.line} />
                    {/* <Location style={styles.icon} /> */}
                    <Text style={styles.orderText}>
                      {pickupInfo.city}, {pickupInfo.state} {pickupInfo.zipCode}
                    </Text>
                  </View>
                  {/* <Text>Delivery:</Text> */}
                  <View style={styles.orderTextContainer}>
                    <Calendar style={styles.icon} />
                    <Text
                      style={styles.orderText}
                      numberOfLines={1}
                      ellipsizeMode="tail"
                    >
                      Delivery: {formatStringToDate(order.deliveryDate1)}
                    </Text>
                    <Line style={styles.line} />
                    {/* <Location style={styles.icon} /> */}
                    <Text style={styles.orderText}>
                      {deliveryInfo.city}, {deliveryInfo.state}{" "}
                      {deliveryInfo.zipCode}
                    </Text>
                  </View>
                </View>
                {order.statusToTMS === "draft" && (
                  <Text style={styles.draftText}>
                    Draft: click button below to send
                  </Text>
                )}
                <View style={styles.buttonRow}>
                  {order.status === "auction" && (
                    <View style={styles.completeButton}>
                      <Button
                        onPress={() => {
                          navigate(`/bids/${order.id}`);
                        }}
                      >
                        <Text style={styles.buttonText}>Show Bids</Text>
                      </Button>
                    </View>
                  )}

                  {order.status === "completed" && (
                    <View style={styles.completeButton}>
                      <Button
                        isDisabled={!active}
                        onPress={() => {
                          setCurrentOrder(order);
                          setRateModalIsOpen(true);
                        }}
                      >
                        <Text style={styles.buttonText}>Rate Order</Text>
                      </Button>
                    </View>
                  )}

                  {order.statusToTMS === "sending" && (
                    <View style={styles.sendingContainer}>
                      <Spinner size="small" color="#0000ff" />
                      <Text style={styles.sendingText}>
                        Order is being sent...
                      </Text>
                    </View>
                  )}

                  {order.isCancelling && (
                    <View style={styles.sendingContainer}>
                      <Spinner size="small" color="#0000ff" />
                      <Text style={styles.sendingText}>
                        Your order is being cancelled...
                      </Text>
                    </View>
                  )}

                  {(order.status === "completedAndRated" ||
                    order.status === "carrierProblem") && (
                    <View style={styles.completeButton}>
                      <Button
                        onPress={() => {
                          navigate(`/order/${order.id}`);
                        }}
                      >
                        <Text style={styles.buttonText}>Order Details</Text>
                      </Button>
                    </View>
                  )}

                  {order.status === "waitingPickUp" && !order.isCancelling && (
                    <View style={styles.buttonsContainer}>
                      <View style={styles.completeButton}>
                        <Button
                          isDisabled={!active}
                          onPress={() => {}}
                          variant="warning"
                        >
                          <Text style={styles.buttonText}>Request Edit</Text>
                        </Button>
                      </View>
                      <View style={styles.completeButton}>
                        <Button
                          isDisabled={!active}
                          onPress={() => {
                            handleCancelClick();
                          }}
                          variant="fatal"
                        >
                          <Text style={styles.buttonText}>Cancel Order</Text>
                        </Button>
                      </View>
                    </View>
                  )}

                  {order.status === "pickedUp" && (
                    <View style={styles.completeButton}>
                      <Button
                        isDisabled={!active}
                        onPress={() => {
                          console.log("To be continued...");
                        }}
                        variant="warning"
                      >
                        <Text style={styles.buttonText}>Request Edit</Text>
                      </Button>
                    </View>
                  )}

                  {order.status === "new" &&
                    (order.statusToTMS === "notsent" ||
                      order.statusToTMS === "draft") && (
                      <View style={styles.completeButton}>
                        <Button
                          isDisabled={!active}
                          onPress={() => {
                            sendOrder(order);
                          }}
                          variant="warning"
                        >
                          <Text style={styles.buttonText}>Send Order</Text>
                        </Button>
                      </View>
                    )}

                  {(order.status === "delivered" ||
                    order.status === "dispute") && (
                    <View style={styles.buttonsContainer}>
                      <View style={styles.completeButton}>
                        <Button
                          isDisabled={!active}
                          onPress={() => {
                            setCurrentOrder(order);
                            setCompleteModalIsOpen(true);
                          }}
                        >
                          <Text style={styles.buttonText}>Complete Order</Text>
                        </Button>
                      </View>

                      {order.status === "delivered" && (
                        <View style={styles.completeButton}>
                          <Button
                            isDisabled={!active}
                            onPress={() => {
                              setCurrentOrder(order);
                              setDisputeModalIsOpen(true);
                            }}
                            variant="fatal"
                          >
                            <Text style={styles.buttonText}>Start dispute</Text>
                          </Button>
                        </View>
                      )}
                    </View>
                  )}
                </View>
              </View>
            </View>
          </Pressable>
        </View>
        <SelectReasonModal />
        <CancelWithoutFeeAlert />
        <CancelWithFeeAlert />
      </>
    );
  };

  const CompleteAlert = () => {
    const onClose = () => {
      setCompleteModalIsOpen(false);
    };
    const complete = () => {
      setCompleteModalIsOpen(false);
      dispatch(
        completeOrder({
          data: {
            ...currentOrder,
          },
        })
      )
        .unwrap()
        .then(() => {
          dispatch(updateOrder({ id: currentOrder.id, status: "completed" }));
          toast.show({
            description: "Order marked as completed successfully.",
            ...toastSuccessConfig,
          });
          setRateModalIsOpen(true);
        })
        .catch((error) => {
          toast.show({
            description: "Failed to complete the order. Please try again.",
            ...toastErrorConfig,
          });
          console.error("Error completing order:", error);
        });
    };
    return (
      <AlertDialog
        leastDestructiveRef={completeRef}
        isOpen={completeModalIsOpen}
        onClose={onClose}
        useRNModal={true}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Complete Order</AlertDialog.Header>
          <AlertDialog.Body>
            Are you sure that you want to mark the order as a completed? After
            that Dispute will be unavailable and we will send the money to the
            carrier
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <View style={styles.buttonsRow}>
              <Button
                style={styles.cancel}
                variant="subtle"
                colorScheme="coolGray"
                onPress={onClose}
                ref={completeRef}
              >
                Cancel
              </Button>
              <Button style={styles.acButton} onPress={complete}>
                Complete
              </Button>
            </View>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  const DisputeAlert = () => {
    const [disputeText, setDisputeText] = useState("");
    const [assets, setAssets] = useState([]);
    const [images, setImages] = useState([]);
    const onClose = () => {
      setDisputeModalIsOpen(false);
    };
    const sendDispute = () => {
      setDisputeModalIsOpen(false);
      dispatch(
        disputeOrder({
          data: {
            id: currentOrder.id,
            disputeText,
            disputeImages: images,
          },
        })
      )
        .unwrap()
        .then(() => {
          dispatch(updateOrder({ id: currentOrder.id, status: "dispute" }));
          toast.show({
            description: "Order was successfully disputed.",
            ...toastSuccessConfig,
          });
        })
        .catch((error) => {
          toast.show({
            description: "Failed to dispute the order.",
            ...toastErrorConfig,
          });
          console.error("Error disputing order:", error);
        });
    };

    const uploadResource = async ({ file }) => {
      try {
        const formattedFileName = file.name.replace(/\s+/g, "-"); // Format the file name

        const formData = new FormData();
        formData.append("file", file, formattedFileName);

        const result = await instance.post(
          `${API_URL}/orders/upload`,
          formData
        );

        if (result?.data?.fileName) {
          // Set the uploaded image URL in the state
          setImages((images) => [...images, result.data.fileName]);
        }
      } catch (error) {
        toast.show({
          description: "Failed to upload image. Please try again.",
          ...toastErrorConfig,
        });
        console.error("Error uploading image: ", error);
      }
    };

    const onChange = (imageList, addUpdateIndex) => {
      if (addUpdateIndex) {
        uploadResource(imageList[addUpdateIndex[0]]);
      }
      setAssets(imageList);
    };

    return (
      <AlertDialog
        leastDestructiveRef={disputeRef}
        isOpen={disputeModalIsOpen}
        onClose={onClose}
        useRNModal={true}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Dispute Request</AlertDialog.Header>
          <AlertDialog.Body>
            <FormControl.Label>Dispute Request</FormControl.Label>
            <TextArea
              // value={disputeText}
              onChangeText={setDisputeText}
              h={20}
              placeholder="Describe the reason for your dispute"
            />
            <ReactImageUploading
              multiple
              value={assets}
              onChange={onChange}
              maxNumber={10}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                // write your building UI
                <div className="upload__image-wrapper">
                  <div
                    style={styles.dragImages}
                    onClick={onImageUpload}
                    {...dragProps}
                  >
                    <Pressable
                      onPress={onImageUpload}
                      style={styles.addPhotoContainer}
                    >
                      <AddImage />
                      <Text style={styles.addPhotoText}>Add Image</Text>
                    </Pressable>
                  </div>
                  <View style={{ flexDirection: "row" }}>
                    {imageList.map((image, index) => (
                      <div
                        key={index}
                        style={{ marginTop: 10, marginRight: 10 }}
                      >
                        <img src={image["data_url"]} alt="" width="100" />
                        <div style={{ textAlign: "center" }}>
                          <button onClick={() => onImageRemove(index)}>
                            Remove
                          </button>
                        </div>
                      </div>
                    ))}
                  </View>
                </div>
              )}
            </ReactImageUploading>
            <View style={styles.buttonsContainer}></View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                gap: 10,
                marginTop: 15,
              }}
            >
              {assets.map((image, index) => (
                <Pressable onPress={() => {}} key={index}>
                  <Image
                    alt={image.uri}
                    style={styles.image}
                    height={66}
                    width={66}
                    src={image.uri}
                  />
                </Pressable>
              ))}
            </View>
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <View style={styles.buttonsRow}>
              <Button
                style={styles.cancel}
                variant="subtle"
                colorScheme="coolGray"
                onPress={onClose}
                ref={disputeRef}
              >
                Cancel
              </Button>
              <Button style={styles.acButton} onPress={sendDispute}>
                Send
              </Button>
            </View>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  const RateAlert = () => {
    const [rating, setRating] = useState(0);
    const onClose = () => {
      setRateModalIsOpen(false);
    };
    const sendRate = () => {
      setRateModalIsOpen(false);
      dispatch(
        rateOrder({
          data: {
            id: currentOrder.id,
            rating,
            carrierID: currentOrder.carrierID,
          },
        })
      )
        .unwrap()
        .then(() => {
          dispatch(
            updateOrder({ id: currentOrder.id, status: "completedAndRated" })
          );
          toast.show({
            description: "Thank you for your feedback!",
            ...toastSuccessConfig,
          });
        })
        .catch((error) => {
          toast.show({
            description: "Failed to submit rating. Please try again.",
            ...toastErrorConfig,
          });
          console.error("Error rating order:", error);
        });
    };
    return (
      <AlertDialog
        leastDestructiveRef={rateRef}
        isOpen={rateModalIsOpen}
        onClose={onClose}
        useRNModal={true}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Feedback</AlertDialog.Header>
          <AlertDialog.Body>
            We'd welcome your feedback! Please rate the carrier and the service.
            <Center>
              <StarRating mt={20} rating={rating} onChange={setRating} />
            </Center>
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <View style={styles.buttonsRow}>
              <Button
                style={styles.cancel}
                variant="subtle"
                colorScheme="coolGray"
                onPress={onClose}
                ref={rateRef}
              >
                Cancel
              </Button>
              <Button style={styles.acButton} onPress={sendRate}>
                Submit
              </Button>
            </View>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  const EmptyComponent = () => {
    return (
      <Box style={styles.wrapper}>
        <View style={styles.emptyContainer}>
          <EmptyOrdersImage />
          <Text style={styles.emptyText}>
            Your order history is empty. Begin by creating your first order and
            discovering the convenience of our services.
          </Text>
        </View>
      </Box>
    );
  };

  const filteredOrders = (orders) => {
    return orders
      .filter((order) => {
        if (filterStatus === "all") {
          return order.status !== "completedAndRated";
        }
        return order.status === filterStatus;
      })
      .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
  };

  return (
    <ScrollView style={styles.container}>
      <View style={styles.subHeaderWrapper}>
        <Text style={styles.myOrdersTitle}>My Orders</Text>
        <View style={styles.createNewOrder}>
          <Button isDisabled={!active} onPress={createNewOrder}>
            Create Order
          </Button>
        </View>
      </View>

      {/* Status Filter */}
      <Select
        selectedValue={filterStatus}
        minWidth="200"
        accessibilityLabel="Filter by Status"
        placeholder="Filter by Status"
        onValueChange={(itemValue) => setFilterStatus(itemValue)}
      >
        <Select.Item label="All" value="all" />
        <Select.Item label="New" value="new" />
        <Select.Item label="Auction" value="auction" />
        <Select.Item label="Waiting for Pickup" value="waitingPickUp" />
        <Select.Item label="Picked Up" value="pickedUp" />
        <Select.Item label="Delivered" value="delivered" />
        <Select.Item label="Completed" value="completed" />
        <Select.Item label="Disputed" value="dispute" />
        <Select.Item label="Archived" value="completedAndRated" />
      </Select>

      {isLoading ? (
        <Center flex={1} style={{ marginTop: 50 }}>
          <Spinner size="lg" color="#1DCE80" />
          <Text mt={4}>Loading Orders...</Text>
        </Center>
      ) : (
        <FlatList
          style={{ marginTop: 10 }}
          contentContainerStyle={{
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "flex-start",
          }}
          data={filteredOrders(orders)}
          // data={
          //   orders
          //     ?.filter((order) => !order.deleted)
          //     ?.sort(function (a, b) {
          //       return new Date(b.updatedAt) - new Date(a.updatedAt);
          //     }) || []
          // }
          renderItem={({ item }) =>
            item.vehicles && item.vehicles.length > 0 ? (
              <OrderItem order={item} vehicle={item.vehicles[0]} />
            ) : (
              <EmptyComponent />
            )
          }
          keyExtractor={(item) => item.id}
          refreshing={refreshing}
          onRefresh={() => {
            handleRefresh;
          }}
          ListEmptyComponent={() => <EmptyComponent />}
          ListFooterComponent={() => <View style={styles.footerBlock} />}
        />
      )}
      <CompleteAlert />
      <DisputeAlert />
      <RateAlert />
    </ScrollView>
  );
}
