import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../config";
import instance from "../axios";

export const getProfile = createAsyncThunk(
  "profile/get",
  async ({}, { rejectWithValue }) => {
    try {
      const res = await instance.get(`${API_URL}/auth/profile`);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getOrder = createAsyncThunk(
  "order/get",
  async ({ id }, { rejectWithValue }) => {
    try {
      const res = await instance.get(`${API_URL}/orders/${id}`);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateProfilePhone = createAsyncThunk(
  "profile/update/phone",
  async (data, { rejectWithValue }) => {
    try {
      const res = await instance.put(`${API_URL}/auth/profile/phone`, data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateProfileEmail = createAsyncThunk(
  "profile/update/email",
  async (data, { rejectWithValue }) => {
    try {
      const res = await instance.put(`${API_URL}/auth/profile/email`, data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateProfile = createAsyncThunk(
  "profile/update",
  async (data, { rejectWithValue }) => {
    try {
      const res = await instance.put(`${API_URL}/auth/profile`, data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const profileAddFCM = createAsyncThunk(
  "profile/fcm",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await instance.post(`${API_URL}/auth/profile/fcm`, data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
